import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup"; // Import Yup for validation
import axios from "axios"; // Import Axios for API calls
import { AdminContext } from "../../Context/AdminContext"
import logoo from "../../imges/logo.png"
import { FiAlignJustify } from "react-icons/fi";

function AddEPin() {
    let { logOut } = useContext(AdminContext)
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768); // Sidebar open for desktop by default
    let navigate = useNavigate();

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768); // Open sidebar on desktop, close on mobile
        };

        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    async function addEpin(values) {
        try {
            const { data } = await axios.post(
                `https://together-v.com/api/v1/agent/createEPin`,
                values, // Send the form values directly
                {
                    withCredentials: true, // Include credentials (cookies)
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`, // Example token retrieval
                    },
                }
            );
            if (data.message === "Code created successfully") {
                toast.success("E-Pin created successfully");
                formik.resetForm(); // Reset the form after successful submission
            }
        } catch (err) {
            if (err.response && err.response.status === 401) {
                toast.error("Unauthorized. Please log in again.");
            } else {
                toast.error("Failed to create E-Pin");
            }
        }
    }

    const validationSchema = Yup.object({
        value: Yup.number()
            .required("Value is required")
        ,
        quantity: Yup.number()
            .required("quantity is required")
        ,
        expiredDate: Yup.string()
            .required("Expired Date is required")
        ,
    });

    const formik = useFormik({
        initialValues: {
            value: "",
            quantity: "",
            expiredDate: "" // Include value field in the form
        },
        validationSchema,
        onSubmit: addEpin,
    });


    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charset="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                    <title>Add E-Pin</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <header id="header" className="header fixed-top d-flex align-items-center">
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <img
                                src={logoo}
                                alt="Logo"
                                className="logo d-flex align-items-center w-35"
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate('/DashboardAdmin')}
                            />
                        </div>                        <h2 className="menu-icon" onClick={toggleSidebar}>
                            <FiAlignJustify />
                        </h2>
                    </div>
                </header>
            </header>


            <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/DashboardAdmin">
                            <i className="bi bi-grid"></i>
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Agents">
                            <i className="bi bi-grid"></i>
                            <span>All Agents</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEpin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Epin</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EPin">
                            <i className="bi bi-grid"></i>
                            <span>E-Pins</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EpinHistory">
                            <i className="bi bi-grid"></i>
                            <span>E-Pins History</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddProduct">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Product</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyTreeAdmin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>My Tree</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEvent">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Event</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProducts">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>All Products</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDrawRequest">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Withdraw Requests</span>
                        </Link>
                    </li>

                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Reports</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/MyTransfers">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>My Transfers</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllRequestsProducts">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Purchase</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/TransferRequests">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Transfers</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllWithDrawsAdmin">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Withdraws</span>
                                </Link>
                            </li>
                        </ul>
                    </li>


                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Filtration">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Filtration</span>
                        </Link>
                    </li>
                    <li className="nav-heading">Pages</li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllContactUs">
                            <i className="bi bi-person"></i>
                            <span>All Contact</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ProfileAdmin">
                            <i className="bi bi-person"></i>
                            <span>Profile</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" onClick={() => logOut()}>
                            <i className="bi bi-person"></i>
                            <span>Log Out</span>
                        </Link>
                    </li>
                </ul>
            </aside>



            <div id="main" className={`${isSidebarOpen ? "" : "sidebar-open"}`}>
                <div className="pagetitle">
                    <h1>Add E-Pin</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Add E-Pin</li>
                        </ol>
                    </nav>
                </div>
                <div className="p-4 rounded-1 bg-white shadow-3">
                    <div className="col-xl-10">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row gy-4">

                                <div className="col-12">
                                    <div className="form-input">
                                        <input
                                            type="number"
                                            name="value"
                                            value={formik.values.value}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className={formik.touched.value && formik.errors.value ? "is-invalid" : ""}
                                            required
                                        />
                                        <label className="lh-1 text-16 text-light-1">Value</label>
                                        {formik.touched.value && formik.errors.value && (
                                            <div className="invalid-feedback">{formik.errors.value}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-input">
                                        <input
                                            type="number"
                                            name="quantity"
                                            value={formik.values.quantity}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className={formik.touched.quantity && formik.errors.quantity ? "is-invalid" : ""}
                                            required
                                        />
                                        <label className="lh-1 text-16 text-light-1">Quantity</label>
                                        {formik.touched.quantity && formik.errors.quantity && (
                                            <div className="invalid-feedback">{formik.errors.quantity}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-input">
                                        <input
                                            type="date"
                                            name="expiredDate"
                                            value={formik.values.expiredDate}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className={formik.touched.expiredDate && formik.errors.expiredDate ? "is-invalid" : ""}
                                            required
                                        />
                                        <label className="lh-1 text-16 text-light-1">Expired Date</label>
                                        {formik.touched.expiredDate && formik.errors.expiredDate && (
                                            <div className="invalid-feedback">{formik.errors.expiredDate}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <button
                                        className="button md blue-1 bg-blue-1-05 text-blue-1 mt-2"
                                        type="submit"
                                        disabled={formik.isSubmitting}
                                    >
                                        {formik.isSubmitting ? "Submitting..." : "Add E-Pin"}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>




        </>
    );
}

export default AddEPin;

import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { AdminContext } from "../../Context/AdminContext"
import { jsPDF } from "jspdf";
import logoo from "../../imges/logo.png"
import { FiAlignJustify } from "react-icons/fi";
import gold from "../../imges/gold.jpg";
import red from "../../imges/red.png";
import silver from "../../imges/silver.avif";
import bronze from "../../imges/bronze.jpg";
import { BallTriangle } from 'react-loader-spinner'
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
function MyTreeAdmin() {
    let { logOut } = useContext(AdminContext);
    const [visibility, setVisibility] = useState({});
    const [selectedMember, setSelectedMember] = useState(null); // For modal
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768); // Sidebar open for desktop by default
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768); // Open sidebar on desktop, close on mobile
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    // Fetch tree data
    function getAgentTree() {
        return axios.get(`https://together-v.com/api/v1/agent/getAgentTree`, {
            withCredentials: true,
        });
    }

    const { data, isLoading } = useQuery("getAgentTree", getAgentTree);
    console.log(data?.data.data)
    const toggleVisibility = (id) => {
        setVisibility((prev) => ({
            ...prev,
            [id]: !prev[id], // Toggle visibility for this node
        }));
    };

    const fetchMemberDetails = async (memberId) => {
        try {
            const response = await axios.get(
                `https://together-v.com/api/v1/agent/getAgentByAdmin/${memberId}`,
                { withCredentials: true }
            );
            setSelectedMember(response.data.data);
        } catch (error) {
            toast.error("Failed to fetch member details.");
        }
    };

    const renderMember = (member) => {
        if (!member) return null; // Stop recursion if no member is provided
    
        const getImageByLevel = (level) => {
            switch (level) {
                case "red":
                    return red;
                case "gold":
                    return gold;
                case "silver":
                    return silver;
                case "bronze":
                    return bronze;
                default:
                    return "assets/img/default.jpg";
            }
        };
    
        const navigateToAddAgent = (parentId, position) => {
            navigate(`/AddAgentAdmin?parentId=${parentId}&position=${position}`);
        };
    
        return (
            <li key={member._id} style={{ listStyleType: "none" }}>
                {/* Current Member */}
                <div className="member-view-box" style={{ textAlign: "center", marginBottom: "20px" }}>
                    <div className="member-image">
                        <img
                            src={getImageByLevel(member.level)}
                            alt={member.level}
                            style={{ cursor: "pointer", width: "50px", height: "50px" }}
                            onClick={() => toggleVisibility(member._id)} // Toggle visibility
                        />
                        <div className="member-details mt-2">
                            <h3
                                className="fnt-10px"
                                style={{ cursor: "pointer", color: "blue" }}
                                onClick={() => fetchMemberDetails(member._id)} // Fetch details
                            >
                                01: {member._id}
                            </h3>
                        </div>
                    </div>
    
                    {/* Left and Right Children */}
                    {visibility[member._id] && (
                        <div
                            className="children-container"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            {/* Left Child */}
                            {member.leftChild ? (
                                <div className="child-detail" style={{ textAlign: "center" }}>
                                    <div className="member-image">
                                        <img
                                            src={getImageByLevel(member.level)}
                                            alt="Left Child"
                                            style={{ cursor: "pointer", width: "50px", height: "50px" }}
                                            onClick={() => fetchMemberDetails(member.leftChild)}
                                        />
                                        <div
                                            className="fnt-10px"
                                            style={{ cursor: "pointer", color: "blue" }}
                                            onClick={() => fetchMemberDetails(member.leftChild)}
                                        >
                                            02: {member.leftChild}
                                        </div>
                                        <ul style={{ display: "flex", justifyContent: "center", padding: 0 }}>
                                            {member.leftChild0 ? (
                                                <li style={{ margin: "0 10px" }} className="fnt-10px">
                                                    {renderMember(member.leftChild0)}
                                                </li>
                                            ) : (
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={() => navigateToAddAgent(member.leftChild, "left0")}
                                                >
                                                    +
                                                </button>
                                            )}
                                            {member.rightChild0 ? (
                                                <li style={{ margin: "0 10px" }} className="fnt-10px">
                                                    {renderMember(member.rightChild0)}
                                                </li>
                                            ) : (
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={() => navigateToAddAgent(member.leftChild, "right0")}
                                                >
                                                    +
                                                </button>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            ) : (
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => navigateToAddAgent(member._id, "leftChild")}
                                >
                                    +
                                </button>
                            )}
    
                            {/* Right Child */}
                            {member.rightChild ? (
                                <div className="child-detail" style={{ textAlign: "center" }}>
                                    <img
                                        src={getImageByLevel(member.level)}
                                        alt="Right Child"
                                        style={{
                                            cursor: "pointer",
                                            width: "50px",
                                            height: "50px",
                                        }}
                                        onClick={() => fetchMemberDetails(member.rightChild)}
                                    />
                                    <div
                                        className="fnt-10px"
                                        style={{ cursor: "pointer", color: "blue" }}
                                        onClick={() => fetchMemberDetails(member.rightChild)}
                                    >
                                        03: {member.rightChild}
                                    </div>
                                    <ul style={{ display: "flex", justifyContent: "center", padding: 0 }}>
                                        {member.leftChild1 ? (
                                            <li style={{ margin: "0 10px" }} className="fnt-10px">
                                                {renderMember(member.leftChild1)}
                                            </li>
                                        ) : (
                                            <button
                                                className="btn btn-primary btn-sm"
                                                onClick={() => navigateToAddAgent(member.rightChild, "left1")}
                                            >
                                                +
                                            </button>
                                        )}
                                        {member.rightChild1 ? (
                                            <li style={{ margin: "0 10px" }} className="fnt-10px">
                                                {renderMember(member.rightChild1)}
                                            </li>
                                        ) : (
                                            <button
                                                className="btn btn-primary btn-sm"
                                                onClick={() => navigateToAddAgent(member.rightChild, "right1")}
                                            >
                                                +
                                            </button>
                                        )}
                                    </ul>
                                </div>
                            ) : (
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => navigateToAddAgent(member._id, "rightChild")}
                                >
                                    +
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </li>
        );
    };
    








    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                    <title>MY Tree</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <header id="header" className="header fixed-top d-flex align-items-center">
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <img
                                src={logoo}
                                alt="Logo"
                                className="logo d-flex align-items-center w-35"
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate('/DashboardAdmin')}
                            />
                        </div>

                        <h2 className="menu-icon" onClick={toggleSidebar}>
                            <FiAlignJustify />
                        </h2>
                    </div>
                </header>
            </header>

            <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/DashboardAdmin">
                            <i className="bi bi-grid"></i>
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Agents">
                            <i className="bi bi-grid"></i>
                            <span>All Agents</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEpin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Epin</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EPin">
                            <i className="bi bi-grid"></i>
                            <span>E-Pins</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EpinHistory">
                            <i className="bi bi-grid"></i>
                            <span>E-Pins History</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddProduct">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Product</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyTreeAdmin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>My Tree</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEvent">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Event</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProducts">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>All Products</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDrawRequest">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Withdraw Requests</span>
                        </Link>
                    </li>

                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Reports</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/MyTransfers">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>My Transfers</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllRequestsProducts">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Purchase</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/TransferRequests">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Transfers</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllWithDrawsAdmin">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Withdraws</span>
                                </Link>
                            </li>
                        </ul>
                    </li>


                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Filtration">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Filtration</span>
                        </Link>
                    </li>
                    <li className="nav-heading">Pages</li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllContactUs">
                            <i className="bi bi-person"></i>
                            <span>All Contact</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ProfileAdmin">
                            <i className="bi bi-person"></i>
                            <span>Profile</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" onClick={() => logOut()}>
                            <i className="bi bi-person"></i>
                            <span>Log Out</span>
                        </Link>
                    </li>
                </ul>
            </aside>


            <section>
                <div id="main" className="bg-light-2">
                    <div className="pagetitle">
                        <h1>My Tree</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="breadcrumb-item">Dashboard</li>
                                <li className="breadcrumb-item active">Tree</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="notice-container">

                        <div className="notice-icons">
                            <div className="icon">
                                <div className="circle green"></div>
                                <span>Stater</span>
                            </div>
                            <div className="icon">
                                <div className="circle bronze"></div>
                                <span>Bronze</span>
                            </div>
                            <div className="icon">
                                <div className="circle silver"></div>
                                <span>Silver</span>
                            </div>
                            <div className="icon">
                                <div className="circle gold"></div>
                                <span>Gold</span>
                            </div>
                            <div className="icon">
                                <div className="circle red"></div>
                                <span>Inactive</span>
                            </div>
                        </div>
                    </div>
                    <h4 className="text-center mt-3">The Agent Tree:</h4>
                    <div className="body genealogy-body genealogy-scroll">
                        {isLoading ? (
                            <div className="'w-100 py-5 d-flex justify-content-center">
                                <BallTriangle
                                    height={100}
                                    width={100}
                                    radius={5}
                                    color="#4fa94d"
                                    ariaLabel="ball-triangle-loading"
                                    visible={true}
                                />
                            </div>
                        ) : (
                            <div className="genealogy-tree">
                                <ul>
                                    {data?.data?.data && renderMember(data.data.data)} {/* Render the tree */}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </section>

            {/* Modal to show member details */}
            <Modal show={!!selectedMember} onHide={() => setSelectedMember(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Member Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedMember ? (
                        <div>
                            <p><strong>ID:</strong> {selectedMember._id}</p>
                            <p><strong>Name:</strong> {selectedMember.firstName} {selectedMember.secondName} {selectedMember.thirdName} {selectedMember.lastName}</p>
                            <p><strong>Email:</strong> {selectedMember.email}</p>
                            <p><strong>PV:</strong> {selectedMember.pv}</p>
                            <p><strong>Balance:</strong> {selectedMember.balance}</p>
                            <p><strong>Left Child:</strong> {selectedMember.leftChild || "None"}</p>
                            <p><strong>Right Child:</strong> {selectedMember.rightChild || "None"}</p>
                        </div>
                    ) : (
                        <p>Loading...</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setSelectedMember(null)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}

export default MyTreeAdmin;





import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import {AgentContext} from "../../Context/AgentContext"
import logoo from "../../imges/logo.png"
import { IoIosSettings } from "react-icons/io";

import { FiAlignJustify } from "react-icons/fi";

function Events() {
    let {logOut} = useContext(AgentContext)
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768); // Sidebar open for desktop by default
    let navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768); // Open sidebar on desktop, close on mobile
        };

        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    function getAllEvents() {
        const limit = 1000000;
        const page = 1;

        return axios.get(`https://together-v.com/api/v1/event/allEvents`, {
            params: {
                limit,
                page,
            },
            withCredentials: true
        });
    }

    let { data } = useQuery('getAllEvents', getAllEvents);   
    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charset="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                    <title>Events</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <div className="d-flex align-items-center ">
               <div>
                        <img
                            src={logoo}
                            alt="Logo"
                            className="logo d-flex align-items-center w-35"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate('/Dashboard')}
                        />
                    </div>
                    <h2 className="menu-icon" onClick={toggleSidebar}>
                        <FiAlignJustify />
                    </h2>
                </div>
                <nav class="header-nav ms-auto">
                    <ul class="d-flex align-items-center">
                        <li class="nav-item dropdown pe-3">
                            <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                <div className="ic">
                                    <IoIosSettings />
                                </div>
                                <span class="d-none d-md-block dropdown-toggle ps-2">Settings</span>

                            </a>
                            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">


                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" to="/profile">
                                        <i className="bi bi-person"></i>
                                        <span>My Profile</span>
                                    </Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" onClick={() => logOut()} >
                                        <i className="bi bi-box-arrow-right"></i>
                                        <span>Sign Out</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </header>


            <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/dashboard">
                            <i className="bi bi-grid"></i>
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/tree">
                            <i className="bi bi-grid"></i>
                            <span>My Tree</span>
                        </Link>
                    </li>


                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/addagent">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Agent</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProductsAgent">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>All Products</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Events">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>All Events</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyProducts">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>MY Products</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Transfer">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Transfers</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDraw">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Withdraw</span>
                        </Link>
                    </li>
                    <li className="nav-heading">Pages</li>
                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Reports</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li>
                                <Link className="dropdown-item" to="/BalanceHistoryAgent">
                                    Balance History
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/AgentTransferHistory">
                                    Trasnfer History
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/MyWithdrawAgent">
                                    Withdraw History
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ContactUs">
                            <i className="bi bi-person"></i>
                            <span>Contact Us</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyWallet">
                            <i className="bi bi-person"></i>
                            <span>My Wallet</span>
                        </Link>
                    </li>

                </ul>
            </aside>

            <section>
                <div id="main" className="bg-light-2">
                    <div className="pagetitle">
                        <h1>All UP coming Events</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                                <li className="breadcrumb-item">Dashboard</li>
                                <li className="breadcrumb-item active">All UP coming Events</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="body genealogy-body genealogy-scroll">
                        <div className="container">
                            <div className="row">

                                <div className="col-lg-12">
                                    <h2>All UP coming Events</h2>
                                    <div className="row">
                                        {data?.data.data.map((epin) => (<div key={epin._id} className="col-lg-6">

                                            <div><img height={200} src={epin.img} alt="" /></div>
                                            <div >
                                                <p>{epin.createdAt.split("T")[0]}</p>
                                                <h3>{epin.description}</h3>
                                                <p>{epin.address}</p>
                                            </div>
                                        </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>

           
        </>
    );
}

export default Events;

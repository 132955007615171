import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import menu_user from "../../imges/menu_user.svg";
import apple from "../../imges/apple-store.svg";
import google from "../../imges/google-play.svg";
import axios from "axios";
import { Audio } from 'react-loader-spinner';
import wsusimg8 from "../../imges/wsus-img-2024-06-04-12-31-51-4487.svg";
import toast from "react-hot-toast";
import { AgentContext } from "../../Context/AgentContext";

function ForgetPassword() {
    const [step, setStep] = useState(1); // Track which form to display
    const [email, setEmail] = useState(""); // Store email for each step
    const [otp, setOtp] = useState(new Array(6).fill("")); // OTP input
    const [newPassword, setNewPassword] = useState(""); // Store new password
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Step 1: Request Password Reset (Sends email)
    async function resetPassword() {
        setLoading(true);
        try {
            const { data } = await axios.post(`https://together-v.com/api/v1/agent/password/requset-reset`, { email });
            if (data.message === 'OTP sent successfully') {
                setLoading(false);
                setStep(2); // Show OTP form
                toast.success("OTP sent to your email!");
            }
        } catch (err) {
            setLoading(false);
            setError("Failed to send OTP. Please check your email and try again.");
        }
    }

    // Step 2: Verify OTP
    async function submitOtp() {
        setLoading(true);
        try {
            const response = await axios.post(`https://together-v.com/api/v1/agent/password/verify-otp`, {
                email: email,
                code: otp.join(""), // Concatenate OTP digits
            });
            if (response.data.message === "OTP verified successfully") {
                setLoading(false);
                setStep(3); // Show Reset Password form
                toast.success("OTP Verified Successfully!");
            } else {
                setLoading(false);
                setError("OTP is invalid. Please try again.");
            }
        } catch (err) {
            setLoading(false);
            setError("OTP verification failed. Please check your OTP and try again.");
        }
    }

    // Step 3: Reset Password
    async function resetPasswordFinal() {
        setLoading(true);
        try {
            const response = await axios.post(`https://together-v.com/api/v1/agent/password/reset`, {
                email: email,
                password: newPassword,
            });
            if (response.data.message === "Password reset successfully") {
                setLoading(false);
                toast.success("Password reset successful! Please log in.");
                navigate("/login");
            }
        } catch (err) {
            setLoading(false);
            setError("Failed to reset password. Please try again.");
        }
    }

    // Handle OTP input change
    const handleOtpChange = (element, index) => {
        if (isNaN(element.value)) return;
        const newOtp = [...otp];
        newOtp[index] = element.value;
        setOtp(newOtp);

        // Focus next input
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };
    return <>
       <header>
                      <Helmet>
                          <meta charset="UTF-8" />
                          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                          <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css" rel="stylesheet" />
                          <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" rel="stylesheet" />
                          <link href="https://cdnjs.cloudflare.com/ajax/libs/mdb-ui-kit/7.3.0/mdb.min.css" rel="stylesheet" />
                          <link rel="stylesheet" href="./css/main.css" />
                          <title>Log In</title>
                      </Helmet>
                  </header>


        <main>
            <section className="con1 py-70">
                <div className="container">
                    <div className="text">
                        <h1>{step === 1 ? "Request Password Reset" : step === 2 ? "OTP Verification" : "Reset Password"}</h1>
                    </div>
                </div>
            </section>

            <section className="sig1 py-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 form">
                            {error && <div className="alert alert-danger">{error}</div>}

                            {/* Step 1: Email Input Form */}
                            {step === 1 && (
                                <>
                                    <h3>Enter Your Email</h3>
                                    <input
                                        type="email"
                                        className="form-control mb-3"
                                        placeholder="Enter your email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    {loading ? (
                                        <button type="button" className="btn d-flex justify-content-center">
                                            <Audio height="50" width="50" color="white" />
                                        </button>
                                    ) : (
                                        <button className="btn" onClick={resetPassword}>
                                            Send OTP
                                        </button>
                                    )}
                                </>
                            )}

                            {/* Step 2: OTP Verification Form */}
                            {step === 2 && (
                                <>
                                    <h3>Enter OTP</h3>
                                    <p>We have sent an OTP to your email.</p>
                                    <div className="otp-container d-flex flex-row">
                                        {otp.map((data, index) => (
                                            <input
                                                key={index}
                                                type="text"
                                                maxLength="1"
                                                value={data}
                                                className="otp-input"
                                                onChange={(e) => handleOtpChange(e.target, index)}
                                            />
                                        ))}
                                    </div>
                                    {loading ? (
                                        <button type="button" className="btn d-flex justify-content-center">
                                            <Audio height="50" width="50" color="white" />
                                        </button>
                                    ) : (
                                        <button className="btn mt-4" onClick={submitOtp}>
                                            Verify OTP
                                        </button>
                                    )}
                                </>
                            )}

                            {/* Step 3: Reset Password Form */}
                            {step === 3 && (
                                <>
                                    <h3>Reset Password</h3>
                                    <input
                                        type="password"
                                        className="form-control mb-3"
                                        placeholder="Enter new password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                    {loading ? (
                                        <button type="button" className="btn d-flex justify-content-center">
                                            <Audio height="50" width="50" color="white" />
                                        </button>
                                    ) : (
                                        <button className="btn mt-4" onClick={resetPasswordFinal}>
                                            Reset Password
                                        </button>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </main>



        <footer>

            <footer className="text-center text-lg-start text-muted py-70">
                <section >
                    <div className="container-fluid text-center text-md-start mt-5">
                        <div className="row mt-3">

                            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4 div1">
                                <p>
                                    We offer a wide range of courses in various subjects, from business and technology to art and personal
                                    development
                                </p>
                                <p>4408 Norma Lane Shreveport, LA 71101</p>
                                <p>+962 790-146-621</p>
                            </div>



                            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4 div2">

                                <h6 className="text-uppercase fw-bold mb-4">
                                    Useful Links
                                </h6>
                                <p>
                                    <Link to="#!" className="text-reset">Home </Link>
                                </p>
                                <p>
                                    <Link to="#!" className="text-reset"> Courses</Link>
                                </p>
                                <p>
                                    <Link to="#!" className="text-reset">Blog</Link>
                                </p>
                                <p>
                                    <Link to="#!" className="text-reset"> About Us </Link>
                                </p>
                                <p>
                                    <Link to="#!" className="text-reset"> Contact</Link>
                                </p>
                            </div>



                            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">

                                <h6 className="text-uppercase fw-bold mb-4">
                                    Our Company
                                </h6>
                                <p>
                                    <Link to="#!" className="text-reset">Contact Us </Link>
                                </p>
                                <p>
                                    <Link to="#!" className="text-reset">Privacy Policy</Link>
                                </p>
                                <p>
                                    <Link to="#!" className="text-reset"> Terms and Conditions</Link>
                                </p>
                                <p>
                                    <Link to="#!" className="text-reset"> Become Teacher</Link>
                                </p>
                                <p>
                                    <Link to="#!" className="text-reset"> All Instructors</Link>
                                </p>
                            </div>

                            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4 div4">

                                <h6 className="text-uppercase fw-bold mb-4">Get In Touch
                                </h6>
                                <p>If you need any kind of help you can know us on socials or mail us </p>

                                <div className="social">
                                    <Link to="" className="me-4 text-reset">
                                        <i className="fab fa-facebook-f"></i>
                                    </Link>
                                    <Link to="" className="me-4 text-reset">
                                        <i className="fab fa-linkedin"></i>
                                    </Link>
                                    <Link to="" className="me-4 text-reset">
                                        <i className="fa-brands fa-whatsapp"></i>
                                    </Link>
                                    <Link to="" className="me-4 text-reset">
                                        <i className="fa-brands fa-youtube"></i>
                                    </Link>

                                </div>

                                <div className="downlod">
                                    <Link to="#"><img src={google} alt="photo" /></Link>
                                    <Link to="#"> <img src={apple} alt="photo" /></Link>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>




            </footer>

            <div className=" p-4 div5" >
                © 2010-2024 skillgro.com. All rights reserved.
                <div className="link">
                    <Link to="#">Privacy Policy</Link>
                    <Link to="#">Terms and Conditions</Link>

                </div>
            </div>

        </footer>
    </>;
}

export default ForgetPassword;

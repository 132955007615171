import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { AgentContext } from "../../Context/AgentContext"
import logoo from "../../imges/logo.png"
import { IoIosSettings } from "react-icons/io";

import { FiAlignJustify } from "react-icons/fi";
import { useFormik } from "formik";

function AllProductsAgent() {
    const { logOut } = useContext(AgentContext);
    const navigate = useNavigate();
    const [countries, setCountries] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768);

    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get("https://restcountries.com/v3.1/all");
                setCountries(response.data);
            } catch (error) {
                console.error("Error fetching countries data:", error);
                toast.error("Failed to load countries data.");
            }
        };
        fetchCountries();
    }, []);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const { data: products } = useQuery("getAllProducts", async () => {
        const response = await axios.get("https://together-v.com/api/v1/product/allProducts", {
            params: { limit: 1000000, page: 1 },
            withCredentials: true,
        });
        return response.data;
    });

    const formik = useFormik({
        initialValues: {
            governorate: "",
            city: "",
            street: "",
            buildingNumber: "",
            floorNumber: "",
            apartmentNumber: "",
            phone: "",
        },
        onSubmit: async (values) => {
            setShowConfirmation(true);
        },
    });

    const handleConfirmPurchase = async () => {
        try {
            const token = localStorage.getItem("AgentToken");

            // Ensure city is included as an empty string if not provided
            const payload = {
                ...formik.values,
                city: formik.values.city || "", // Include city as an empty string if not provided
            };

            const response = await axios.post(
                `https://together-v.com/api/v1/enrollment/${selectedProductId}`,
                payload,
                {
                    headers: { Authorization: `Bearer ${token}` },
                    withCredentials: true,
                }
            );

            if (response.data.message === "product bought successfully") {
                toast.success("Product bought successfully.");
                setShowConfirmation(false);
                formik.resetForm();
            }
        } catch (error) {
            toast.error(error.response?.data?.message || "Failed to purchase product.");
        }
    };

    const handleCountryChange = (e) => {
        const country = e.target.value;
        formik.setFieldValue("governorate", country);
        formik.setFieldValue("city", ""); // Reset city if country changes
    };
    console.log(products?.data)
    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charset="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />

                    <title>All Products</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <div className="d-flex align-items-center ">
                    <div>
                        <img
                            src={logoo}
                            alt="Logo"
                            className="logo d-flex align-items-center w-35"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate('/Dashboard')}
                        />
                    </div>
                    <h2 className="menu-icon" onClick={toggleSidebar}>
                        <FiAlignJustify />
                    </h2>
                </div>
               <nav class="header-nav ms-auto">
                    <ul class="d-flex align-items-center">
                        <li class="nav-item dropdown pe-3">
                            <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                <div className="ic">
                                    <IoIosSettings />
                                </div>
                                <span class="d-none d-md-block dropdown-toggle ps-2">Settings</span>

                            </a>
                            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">


                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" to="/profile">
                                        <i className="bi bi-person"></i>
                                        <span>My Profile</span>
                                    </Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" onClick={() => logOut()} >
                                        <i className="bi bi-box-arrow-right"></i>
                                        <span>Sign Out</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </header>


              <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/dashboard">
                            <i className="bi bi-grid"></i>
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/tree">
                            <i className="bi bi-grid"></i>
                            <span>My Tree</span>
                        </Link>
                    </li>


                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/addagent">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Agent</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProductsAgent">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>All Products</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Events">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>All Events</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyProducts">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>MY Products</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Transfer">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Transfers</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDraw">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Withdraw</span>
                        </Link>
                    </li>
                    <li className="nav-heading">Pages</li>
                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Reports</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li>
                                <Link className="dropdown-item" to="/BalanceHistoryAgent">
                                    Balance History
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/AgentTransferHistory">
                                    Trasnfer History
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/MyWithdrawAgent">
                                    Withdraw History
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ContactUs">
                            <i className="bi bi-person"></i>
                            <span>Contact Us</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyWallet">
                            <i className="bi bi-person"></i>
                            <span>My Wallet</span>
                        </Link>
                    </li>

                </ul>
            </aside>

            <section>
                <div id="main" className="bg-light-2">
                    <div className="pagetitle">
                        <h1>All Products</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active">All Products</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="body genealogy-body genealogy-scroll">
                        <div className="container">
                            <div className="row">
                                {Array.isArray(products?.data) && products?.data.length > 0 ? (
                                    products.data.map((product) => (
                                        <div key={product._id} className="col-lg-4 mb-4">
                                            <img
                                                src={product.imgs?.[0] || "placeholder.jpg"}
                                                alt={product.name}
                                                className="h-200px w-100"
                                            />
                                            <div className="card-body" align="left">
                                                <p>{product.category}</p>
                                                <h3>{product.name}</h3>
                                                <div className="row">
                                                    <div className="col-lg-6">Price: {product.price} L.E</div>
                                                    <div className="col-lg-6">You will get {product.pv} pv</div>
                                                </div>
                                                <p>Quantity: {product.quantity}</p>
                                            </div>
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    setSelectedProductId(product._id);
                                                    setShowForm(true);
                                                }}
                                            >
                                                Buy Now
                                            </button>
                                        </div>
                                    ))
                                ) : (
                                    <p>No products available</p>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Form Modal */}
                    {showForm && (
                        <div className="modal show d-block">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Fill Your Details</h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => setShowForm(false)}
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <form onSubmit={formik.handleSubmit}>
                                            {/* Country Selection */}
                                            <div className="mb-3">
                                                <label className="form-label">Country</label>
                                                <select
                                                    name="country"
                                                    className="form-control"
                                                    value={formik.values.governorate}
                                                    onChange={handleCountryChange}
                                                >
                                                    <option value="">Select a country</option>
                                                    {countries.map((country) => (
                                                        <option key={country.cca3} value={country.name.common}>
                                                            {country.name.common}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            {/* City Selection */}
                                            {formik.values.governorate === "Egypt" && (
                                                <div className="mb-3">
                                                    <label className="form-label">City</label>
                                                    <select
                                                        name="city"
                                                        className="form-control"
                                                        value={formik.values.city}
                                                        onChange={formik.handleChange}
                                                    >
                                                        <option value="">Select a city</option>
                                                        <option value="Cairo">Cairo</option>
                                                        <option value="Alexandria">Alexandria</option>
                                                        <option value="Giza">Giza</option>
                                                        <option value="Port Said">Port Said</option>
                                                        <option value="Suez">Suez</option>
                                                        <option value="Mansoura">Mansoura</option>
                                                        <option value="Tanta">Tanta</option>
                                                        <option value="Ismailia">Ismailia</option>
                                                        <option value="Faiyum">Faiyum</option>
                                                        <option value="Damietta">Damietta</option>
                                                        <option value="Asyut">Asyut</option>
                                                        <option value="Aswan">Aswan</option>
                                                        <option value="Minya">Minya</option>
                                                        <option value="Beni Suef">Beni Suef</option>
                                                        <option value="Qena">Qena</option>
                                                        <option value="Sohag">Sohag</option>
                                                        <option value="Hurghada">Hurghada</option>
                                                        <option value="Luxor">Luxor</option>
                                                        <option value="Qalyubia">Qalyubia</option>
                                                        <option value="Sharqia">Sharqia</option>
                                                        <option value="Beheira">Beheira</option>
                                                        <option value="Kafr El Sheikh">Kafr El Sheikh</option>
                                                        <option value="Monufia">Monufia</option>
                                                        <option value="Red Sea">Red Sea</option>
                                                        <option value="North Sinai">North Sinai</option>
                                                        <option value="South Sinai">South Sinai</option>
                                                        <option value="Matruh">Matruh</option>
                                                        <option value="New Valley">New Valley</option>
                                                    </select>
                                                </div>
                                            )}

                                            {/* Other Address Fields */}
                                            {["street", "buildingNumber", "floorNumber", "apartmentNumber", "phone"].map(
                                                (field) => (
                                                    <div key={field} className="mb-3">
                                                        <label className="form-label">
                                                            {field.charAt(0).toUpperCase() + field.slice(1)}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name={field}
                                                            className="form-control"
                                                            value={formik.values[field]}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </div>
                                                )
                                            )}
                                            <button className="btn btn-primary" type="submit">
                                                Submit
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Confirmation Modal */}
                    {showConfirmation && (
                        <div className="modal show d-block">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Confirmation</h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => setShowConfirmation(false)}
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        بمجرد قيامك بعملية الشراء يتم خصم المبلغ ولا يحق لك استرجاع المبلغ المخصوم
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            className="btn btn-secondary"
                                            onClick={() => setShowConfirmation(false)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="btn btn-primary"
                                            onClick={handleConfirmPurchase}
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>



        </>

    );
}

export default AllProductsAgent;

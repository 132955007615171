import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import logoo from "../../imges/logo.png"
import { FiAlignJustify } from "react-icons/fi";
import { IoIosSettings } from "react-icons/io";

import { AgentContext } from "../../Context/AgentContext"
import axios from "axios";
import { useQuery } from "react-query";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
function Profile() {
    let { logOut } = useContext(AgentContext)
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768); // Sidebar open for desktop by default
    const [isLoading, setIsLoading] = useState(false); // Add loading state
    let navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768); // Open sidebar on desktop, close on mobile
        };

        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    function getCurrentAgent() {
        return axios.get(`https://together-v.com/api/v1/agent/getAgent`, {
            withCredentials: true,
        });
    }

    let { data } = useQuery("getCurrentAgent", getCurrentAgent);

    const handleProfileUpdate = async (e) => {
        e.preventDefault();

        const updatedData = {
            address: e.target.address.value,
            country: e.target.country.value,
            governorate: e.target.governorate.value,
            city: e.target.city.value,
            inheritorName: e.target.inheritorName.value,
            grandfatherInheritorName: e.target.grandfatherInheritorName.value,
            zipCode: e.target.zipCode.value,
            walletPassword: e.target.walletPassword.value,

        };

        try {
            const token = localStorage.getItem("AgentToken");

            const response = await axios.post(
                `https://together-v.com/api/v1/agent/updateAgent`,
                updatedData,
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.message === "Agent updated successfully") {
                toast.success("Profile updated successfully");
            }
        } catch (err) {
            toast.error(err.response?.data?.message || "An unexpected error occurred.");
        }
    };
    const handlePasswordChange = async (values) => {
        setIsLoading(true); // Set loading to true when submitting

        try {
            const response = await axios.post(
                `https://together-v.com/api/v1/agent/updatePasswordByAgent`,
                {
                    oldPass: values.currentPassword,
                    newPass: values.newPassword,
                },
                { withCredentials: true }
            );

            if (response.data.message === "Password updated successfully") {
                toast.success("Password updated successfully!");
            } else {
                toast.error("Failed to update password");
            }
        } catch (error) {
            toast.error(error.response?.data?.message || "An error occurred");
        }
        finally {
            setIsLoading(false); // Set loading to false after completion
        }
    };
    const passwordFormik = useFormik({
        initialValues: {
            currentPassword: "",
            newPassword: "",
            reenterPassword: "",
        },
        validationSchema: Yup.object({
            currentPassword: Yup.string()
                .required("Current password is required"),
            newPassword: Yup.string()
                .required("New password is required")
                .min(6, "Password must be at least 6 characters long"),
            reenterPassword: Yup.string()
                .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
                .required("Please re-enter your new password"),
        }),
        onSubmit: handlePasswordChange,
    });
    
    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charset="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />

                    <title>Profile</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <div className="d-flex align-items-center ">
                   <div>
                        <img
                            src={logoo}
                            alt="Logo"
                            className="logo d-flex align-items-center w-35"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate('/Dashboard')}
                        />
                    </div>
                    <h2 className="menu-icon" onClick={toggleSidebar}>
                        <FiAlignJustify />
                    </h2>
                </div>
               <nav class="header-nav ms-auto">
                    <ul class="d-flex align-items-center">
                        <li class="nav-item dropdown pe-3">
                            <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                <div className="ic">
                                    <IoIosSettings />
                                </div>
                                <span class="d-none d-md-block dropdown-toggle ps-2">Settings</span>

                            </a>
                            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">


                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" to="/profile">
                                        <i className="bi bi-person"></i>
                                        <span>My Profile</span>
                                    </Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" onClick={() => logOut()} >
                                        <i className="bi bi-box-arrow-right"></i>
                                        <span>Sign Out</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </header>


              <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/dashboard">
                            <i className="bi bi-grid"></i>
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/tree">
                            <i className="bi bi-grid"></i>
                            <span>My Tree</span>
                        </Link>
                    </li>


                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/addagent">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Agent</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProductsAgent">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>All Products</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Events">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>All Events</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyProducts">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>MY Products</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Transfer">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Transfers</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDraw">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Withdraw</span>
                        </Link>
                    </li>
                    <li className="nav-heading">Pages</li>
                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Reports</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li>
                                <Link className="dropdown-item" to="/BalanceHistoryAgent">
                                    Balance History
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/AgentTransferHistory">
                                    Trasnfer History
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/MyWithdrawAgent">
                                    Withdraw History
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ContactUs">
                            <i className="bi bi-person"></i>
                            <span>Contact Us</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyWallet">
                            <i className="bi bi-person"></i>
                            <span>My Wallet</span>
                        </Link>
                    </li>

                </ul>
            </aside>

            <main id="main" className="main bg-light-2">
                <div className="pagetitle">
                    <h1>Profile</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item">Users</li>
                            <li className="breadcrumb-item active">Profile</li>
                        </ol>
                    </nav>
                </div>


                <section className="section profile">
                    <div className="row justify-content-between">

                        <div className="col-xl-8">
                            <div className="card border-0">
                                <div className="card-body p-3">
                                    <ul className="nav nav-tabs border-bottom" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="overview-tap" data-bs-toggle="pill"
                                                data-bs-target="#profile-overview" type="button" role="tab" aria-controls="profile-overview"
                                                aria-selected="true">
                                                Overview
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="edit-tap" data-bs-toggle="pill" data-bs-target="#profile-edit"
                                                type="button" role="tab" aria-controls="profile-edit" aria-selected="false" tabIndex="-1">
                                                Edit Profile
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="change-password" data-bs-toggle="pill"
                                                data-bs-target="#profile-change-password" type="button" role="tab"
                                                aria-controls="profile-change-password" aria-selected="false" tabIndex="-1">
                                                Change Password
                                            </button>
                                        </li>
                                    </ul>
                                    <div className="tab-content pt-2">
                                        <div className="tab-pane fade profile-overview show active" id="profile-overview" role="tabpanel"
                                            aria-labelledby="#overview-tap">
                                            <h5 className="card-title py-3 mb-2">Profile Details</h5>
                                            <div className="row mb-3">
                                                <div className="col-lg-3 col-md-4 label">Full Name</div>
                                                <div className="col-lg-9 col-md-8">{data?.data.data.firstName} {data?.data.data.secondName} {data?.data.data.thirdName} {data?.data.data.lastName}</div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-lg-3 col-md-4 label">Country</div>
                                                <div className="col-lg-9 col-md-8">{data?.data.data.country}</div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-lg-3 col-md-4 label">Phone</div>
                                                <div className="col-lg-9 col-md-8">{data?.data.data.phone}</div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-lg-3 col-md-4 label">Email</div>
                                                <div className="col-lg-9 col-md-8">{data?.data.data.email}</div>
                                            </div>
                                        </div>


                                        <div className="tab-pane fade profile-edit pt-3" id="profile-edit">
                                            <form onSubmit={handleProfileUpdate}>

                                                <div className="row mb-3">
                                                    <label htmlFor="address" className="col-md-4 col-lg-3 col-form-label">Address</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="address"
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue={data?.data?.data.address || ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label htmlFor="country" className="col-md-4 col-lg-3 col-form-label">Country</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="country"
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue={data?.data?.data.country || ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label htmlFor="governorate" className="col-md-4 col-lg-3 col-form-label">Governorate</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="governorate"
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue={data?.data?.data.governorate || ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label htmlFor="city" className="col-md-4 col-lg-3 col-form-label">City</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="city"
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue={data?.data?.data.city || ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label htmlFor="inheritorName" className="col-md-4 col-lg-3 col-form-label">InheritorName</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="inheritorName"
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue={data?.data?.data.inheritorName || ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label htmlFor="grandfatherInheritorName" className="col-md-4 col-lg-3 col-form-label">GrandfatherInheritorName</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="grandfatherInheritorName"
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue={data?.data?.data.grandfatherInheritorName || ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label htmlFor="zipCode" className="col-md-4 col-lg-3 col-form-label">ZipCode</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="zipCode"
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue={data?.data?.data.zipCode || ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label htmlFor="walletPassword" className="col-md-4 col-lg-3 col-form-label">WalletPassword</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="walletPassword"
                                                            type="text"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="text-center">
                                                    <button type="submit" className="button dark-1 bg-blue-1 md text-white">
                                                        Save Changes
                                                    </button>
                                                </div>
                                            </form>
                                        </div>


                                        <div
                                            className="tab-pane fade profile-change-password pt-3"
                                            id="profile-change-password"
                                            role="tabpanel"
                                            aria-labelledby="#change-password"
                                        >
                                            <form onSubmit={passwordFormik.handleSubmit}>
                                                <div className="row mb-3">
                                                    <label htmlFor="currentPassword" className="col-md-4 col-lg-3 col-form-label">
                                                        Current Password
                                                    </label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="currentPassword"
                                                            type="password"
                                                            className="form-control"
                                                            id="currentPassword"
                                                            value={passwordFormik.values.currentPassword}
                                                            onChange={passwordFormik.handleChange}
                                                            onBlur={passwordFormik.handleBlur}
                                                        />
                                                        {passwordFormik.touched.currentPassword && passwordFormik.errors.currentPassword && (
                                                            <div className="text-danger">{passwordFormik.errors.currentPassword}</div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label htmlFor="newPassword" className="col-md-4 col-lg-3 col-form-label">
                                                        New Password
                                                    </label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="newPassword"
                                                            type="password"
                                                            className="form-control"
                                                            id="newPassword"
                                                            value={passwordFormik.values.newPassword}
                                                            onChange={passwordFormik.handleChange}
                                                            onBlur={passwordFormik.handleBlur}
                                                        />
                                                        {passwordFormik.touched.newPassword && passwordFormik.errors.newPassword && (
                                                            <div className="text-danger">{passwordFormik.errors.newPassword}</div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label htmlFor="reenterPassword" className="col-md-4 col-lg-3 col-form-label">
                                                        Re-enter New Password
                                                    </label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="reenterPassword"
                                                            type="password"
                                                            className="form-control"
                                                            id="reenterPassword"
                                                            value={passwordFormik.values.reenterPassword}
                                                            onChange={passwordFormik.handleChange}
                                                            onBlur={passwordFormik.handleBlur}
                                                        />
                                                        {passwordFormik.touched.reenterPassword && passwordFormik.errors.reenterPassword && (
                                                            <div className="text-danger">{passwordFormik.errors.reenterPassword}</div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="text-center">
                                                    <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                                        {isLoading ? (
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        ) : (
                                                            "Change Password"
                                                        )}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>


        </>
    );
}

export default Profile;

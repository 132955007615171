import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import { AdminContext } from "../../Context/AdminContext"
import { jsPDF } from "jspdf";
import logoo from "../../imges/logo.png"
import { FiAlignJustify } from "react-icons/fi";

import { BallTriangle } from 'react-loader-spinner'
function Agents() {
    let { logOut } = useContext(AdminContext);
    const [searchId, setSearchId] = useState("");
    const [filteredAgent, setFilteredAgent] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768); // Sidebar open for desktop by default
    let navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768); // Open sidebar on desktop, close on mobile
        };

        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    function getAllAgents() {
        return axios.get(`https://together-v.com/api/v1/agent/allAgents`, {
            withCredentials: true,
        });
    }

    const { data, isLoading, isFetching } = useQuery("getAllAgents", getAllAgents);
    const handleSearch = async () => {
        if (searchId.trim() === "") {
            setFilteredAgent(null);
            return;
        }
        try {
            const response = await axios.get(
                `https://together-v.com/api/v1/agent/getAgentByAdmin/${searchId}`,
                {
                    withCredentials: true,
                }
            );
            setFilteredAgent(response.data.data);
        } catch (error) {
            console.error(error);
            setFilteredAgent(null);
        }
    };

    const downloadPDF = () => {
        const doc = new jsPDF();
        const agents = data?.data?.data;

        if (!agents || agents.length === 0) {
            console.error("No agents data available");
            return;
        }

        doc.setFontSize(14);
        doc.text("All Agents Details", 10, 10);

        let y = 20; // Starting Y position for text

        agents.forEach((agent, index) => {
            doc.setFontSize(12);
            doc.text(`Agent ${index + 1}`, 10, y);
            y += 10; // Space between agent entries

            Object.keys(agent).forEach((key) => {
                if (typeof agent[key] === "object") {
                    doc.text(`${key}: ${JSON.stringify(agent[key])}`, 10, y);
                } else {
                    doc.text(`${key}: ${agent[key]}`, 10, y);
                }
                y += 10;

                // Create a new page if the content goes beyond the page height
                if (y > 270) {
                    doc.addPage();
                    y = 10; // Reset Y position for the new page
                }
            });

            y += 10; // Extra space between agents
        });

        // Save the PDF
        doc.save(`AllAgentsDetails.pdf`);
    };

    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charset="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                    <title>Agents</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <header id="header" className="header fixed-top d-flex align-items-center">
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <img
                                src={logoo}
                                alt="Logo"
                                className="logo d-flex align-items-center w-35"
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate('/DashboardAdmin')}
                            />
                        </div>
                        <h2 className="menu-icon" onClick={toggleSidebar}>
                            <FiAlignJustify />
                        </h2>
                    </div>
                </header>
            </header>

            <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/DashboardAdmin">
                            <i className="bi bi-grid"></i>
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Agents">
                            <i className="bi bi-grid"></i>
                            <span>All Agents</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEpin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Epin</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EPin">
                            <i className="bi bi-grid"></i>
                            <span>E-Pins</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EpinHistory">
                            <i className="bi bi-grid"></i>
                            <span>E-Pins History</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddProduct">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Product</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyTreeAdmin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>My Tree</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEvent">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Event</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProducts">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>All Products</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDrawRequest">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Withdraw Requests</span>
                        </Link>
                    </li>

                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Reports</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/MyTransfers">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>My Transfers</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllRequestsProducts">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Purchase</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/TransferRequests">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Transfers</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllWithDrawsAdmin">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Withdraws</span>
                                </Link>
                            </li>
                        </ul>
                    </li>


                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Filtration">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Filtration</span>
                        </Link>
                    </li>
                    <li className="nav-heading">Pages</li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllContactUs">
                            <i className="bi bi-person"></i>
                            <span>All Contact</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ProfileAdmin">
                            <i className="bi bi-person"></i>
                            <span>Profile</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" onClick={() => logOut()}>
                            <i className="bi bi-person"></i>
                            <span>Log Out</span>
                        </Link>
                    </li>
                </ul>
            </aside>




            <section id="main" className={`${isSidebarOpen ? "" : "sidebar-open"}`}>
                <div >
                    <div className="pagetitle">
                        <h1>Agent Details</h1>
                        <button className="btn btn-primary my-3" onClick={downloadPDF}>
                            Download PDF
                        </button>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item">Dashboard</li>
                                <li className="breadcrumb-item active">Agents</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="p-4 rounded-1 bg-white shadow-3">
                        <div className="text-center mb-2">
                            <input
                                type="text"
                                placeholder="Enter Agent ID"
                                value={searchId}
                                onChange={(e) => setSearchId(e.target.value)}
                                className="form-control w-100"
                            />
                            <button
                                className="btn btn-primary w-25 mt-3"
                                onClick={handleSearch}
                            >
                                Search
                            </button>
                        </div>
                        <div className="tabs">
                            <div className="tab-content pt-4">
                                <div
                                    className="tab-pane fade active show"
                                    id="all-booking"
                                    role="tabpanel"
                                >
                                    {isLoading ? <div className="'w-100 py-5 d-flex justify-content-center">
                                        <BallTriangle
                                            height={100}
                                            width={100}
                                            radius={5}
                                            color="#4fa94d"
                                            ariaLabel="ball-triangle-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                        />
                                    </div> : <>
                                        <div className="overflow-scroll scroll-bar-1">
                                            <table className="table col-12">
                                                <thead className="bg-light-2">
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Name</th>
                                                        <th>Email</th>

                                                        <th>Country</th>
                                                        <th>View</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredAgent ? (
                                                        <tr>
                                                            <td>{filteredAgent._id}</td>
                                                            <td>{filteredAgent.firstName}</td>
                                                            <td>{filteredAgent.country}</td>
                                                            <td>
                                                                <Link to={`/UpdateDataAgent/${filteredAgent._id}`} className="btn btn-warning">View</Link>
                                                            </td>
                                                        </tr>
                                                    ) : Array.isArray(data?.data?.data) ? (
                                                        data.data.data.map((agent, index) => (
                                                            <tr key={agent._id}>
                                                                <td>{agent._id}</td>
                                                                <td>{agent.firstName} {agent.lastName}</td>
                                                                <td>{agent.email}</td>

                                                                <td>{agent.country}</td>
                                                                <td>
                                                                    <Link to={`/UpdateDataAgent/${agent._id}`} className="btn btn-warning">View</Link>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="4">No data available</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </>}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}

export default Agents;

import { createContext, useState } from "react";

export let AgentContext = createContext();

export default function AgentContextProvider(props) {
    let [AgentToken, setAgentToken] = useState(null);
    let [AgentRefreshToken, setAgentRefreshToken] = useState(null);

    function logOut() {
        localStorage.removeItem('AgentToken'); 
        localStorage.removeItem('AgentRefreshToken'); 

        setAgentToken(null); 
        setAgentRefreshToken(null);
        window.location.href = '/login';
    }

    return (
        <AgentContext.Provider value={{ AgentToken,AgentRefreshToken,setAgentRefreshToken, setAgentToken, logOut }}>
            {props.children}
        </AgentContext.Provider>
    );
}

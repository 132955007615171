import { createContext, useState } from "react";

export let AdminContext = createContext();

export default function AdminContextProvider(props) {
    let [AdminToken, setAdminToken] = useState(null);
    let [AdminRefreshToken, setAdminRefreshToken] = useState(null);

    function logOut() {
        localStorage.removeItem('AdminToken'); 
        localStorage.removeItem('AdminRefreshToken'); 

        setAdminToken(null); 
        setAdminRefreshToken(null);
        window.location.href = '/login';
    }

    return (
        <AdminContext.Provider value={{ AdminToken,AdminRefreshToken,setAdminRefreshToken, setAdminToken, logOut }}>
            {props.children}
        </AdminContext.Provider>
    );
}

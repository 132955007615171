import axios from "axios";
import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { AgentContext } from "../../Context/AgentContext"
import logoo from "../../imges/logo.png"

function VerifyAgent() {
    const navigate = useNavigate();
    const [code, setCode] = useState(""); // State for verification code
    const [loading, setLoading] = useState(false); // State for button loading

    async function handleVerify(e) {
        e.preventDefault(); // Prevent default form submission behavior
        setLoading(true); // Set loading to true
        try {
            const response = await axios.post(
                "https://together-v.com/api/v1/agent/verifyAgent",
                { code }, // Send the code in the request body
                {
                    withCredentials: true, // Include credentials
                }
            );

            if (response.data) {
                toast.success("Agent verified successfully!");
                setCode(""); // Clear the input field
                navigate("/login");
            }
        } catch (error) {
            toast.error(error.response?.data?.message || "Verification failed.");
        } finally {
            setLoading(false); // Reset loading to false
        }
    }

    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                    <title>Verify</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <div class="d-flex align-items-center justify-content-between">
                    <div>
                        <img
                            src={logoo}
                            alt="Logo"
                            className="logo d-flex align-items-center w-35"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate('/Dashboard')}
                        />
                    </div>
                    <i class="bi bi-list toggle-sidebar-btn"></i>
                </div>
            </header>


            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>Verify Agent</h1>
                </div>

                <section className="section">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Enter Verification Code</h5>
                                    <form onSubmit={handleVerify}>
                                        <div className="form-group">
                                            <label htmlFor="code">Verification Code</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="code"
                                                value={code}
                                                onChange={(e) => setCode(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="text-center mt-3">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                disabled={loading} // Disable button when loading
                                            >
                                                {loading ? (
                                                    <>
                                                        <span className="spinner-border spinner-border-sm me-2" role="status" />
                                                        Verifying...
                                                    </>
                                                ) : (
                                                    "Verify"
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default VerifyAgent;

import React from "react";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Link } from "react-router-dom";
import apple from "../../imges/apple-store.svg";
import google from "../../imges/google-play.svg";
import shape01 from "../../imges/breadcrumb_shape01.svg";
import shape02 from "../../imges/breadcrumb_shape02.svg";
import shape03 from "../../imges/breadcrumb_shape03.svg";
import shape04 from "../../imges/breadcrumb_shape04.svg";

function Signup() {
    const formik = useFormik({
        initialValues: {
            firstName: "",
            secondName: "",
            thirdName: "",
            lastName: "",
            email: "",
            nationalId: "",
            phone: "",
            address: "",
            country: "",
            governorate: "",
            city: "",
            inheritorName: "",
            grandfatherInheritorName: "",
            zipCode: "",
            refId: "",
            direction: "",
            password: "",
            walletPassword: "",
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required("First name is required"),
            secondName: Yup.string().required("Second name is required"),
            thirdName: Yup.string().required("Third name is required"),
            lastName: Yup.string().required("Last name is required"),
            email: Yup.string().email("Invalid email address").required("Email is required"),
            nationalId: Yup.string().length(14, "National ID must be 14 digits").required("National ID is required"),
            phone: Yup.string().required("Phone number is required"),
            address: Yup.string().required("Address is required"),
            country: Yup.string().required("Country is required"),
            governorate: Yup.string().required("Governorate is required"),
            city: Yup.string().required("City is required"),
            inheritorName: Yup.string().required("Inheritor name is required"),
            grandfatherInheritorName: Yup.string().required("Grandfather inheritor name is required"),
            zipCode: Yup.string().required("ZIP code is required"),
            password: Yup.string().min(8, "Password must be at least 8 characters").required("Password is required"),
            walletPassword: Yup.string()
                .oneOf([Yup.ref("password"), null], "Wallet password must match password")
                .required("Wallet password is required"),
        }),
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            try {
                const response = await axios.post(`https://hatcacademy.com/api/v1/agent/register`, values);
                console.log("Signup successful", response.data);
                // Redirect or handle successful signup
            } catch (error) {
                console.error("Signup failed", error.response?.data || error.message);
                setErrors({ api: error.response?.data?.message || "Signup failed" });
            } finally {
                setSubmitting(false);
            }
        },
    });

    return (
        <>
            <header>
                <Helmet>
                    <meta charset="UTF-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <link
                        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css"
                        rel="stylesheet"
                    />
                    <link
                        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
                        rel="stylesheet"
                    />
                    <link
                        href="https://cdnjs.cloudflare.com/ajax/libs/mdb-ui-kit/7.3.0/mdb.min.css"
                        rel="stylesheet"
                    />
                    <title>Sign Up</title>
                </Helmet>
            </header>

            <main>
                <section className="con1 py-70">
                    <div className="container">
                        <img className="img1" src={shape01} alt="shape" />
                        <img className="img2" src={shape02} alt="shape" />
                        <img className="img3" src={shape03} alt="shape" />
                        <img className="img4" src={shape04} alt="shape" />
                        <div className="text">
                            <h1>Register</h1>
                            <p>
                                <a href="#">Home</a>{" "}
                                <i className="fa-solid fa-chevron-right"></i> Register
                            </p>
                        </div>
                    </div>
                </section>

                <section className="sig1 py-70">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12 form">
                                <h3>Create Your Account!</h3>
                                <p>
                                    Enter your details below to create your account. Let’s get started!
                                </p>

                                {formik.errors.api && (
                                    <div className="alert alert-danger">{formik.errors.api}</div>
                                )}

                                <form onSubmit={formik.handleSubmit}>
                                    {Object.keys(formik.initialValues).map((key) => (
                                        <div key={key} className="mb-4 form1">
                                            <label className="form-label" htmlFor={key}>
                                                {key.charAt(0).toUpperCase() + key.slice(1)}
                                            </label>
                                            <input
                                                type={key === "password" || key === "walletPassword" ? "password" : "text"}
                                                id={key}
                                                className={`form-control ${
                                                    formik.touched[key] && formik.errors[key] ? "is-invalid" : ""
                                                }`}
                                                placeholder={key.charAt(0).toUpperCase() + key.slice(1)}
                                                {...formik.getFieldProps(key)}
                                            />
                                            {formik.touched[key] && formik.errors[key] ? (
                                                <div className="invalid-feedback">{formik.errors[key]}</div>
                                            ) : null}
                                        </div>
                                    ))}

                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        disabled={formik.isSubmitting}
                                    >
                                        Sign Up <i className="fa-solid fa-arrow-right"></i>
                                    </button>
                                </form>
                                <p className="sign">
                                    Already have an account? <Link to="/login">Login</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <footer>
                <div className="p-4 div5">
                    © 2010-2024 skillgro.com. All rights reserved.
                    <div className="link">
                        <Link to="#">Privacy Policy</Link>
                        <Link to="#">Terms and Conditions</Link>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Signup;

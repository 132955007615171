import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import hero from "../imges/logo.png"
import hero1 from "../imges/hero-img.png"
import { FaArrowRightLong } from "react-icons/fa6";
import aboutt from "../imges/about.jpg"
import alt from "../imges/alt-features.png"
import { FaAward } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

import val1 from "../imges/values-1.png"
import val2 from "../imges/values-2.png"
import val3 from "../imges/values-3.png"
import serv1 from "../imges/serv/WhatsApp Image 2024-12-12 at 8.41.05 PM.jpeg"
import serv2 from "../imges/serv/WhatsApp Image 2024-12-12 at 8.41.06 PM (1).jpeg"
import serv3 from "../imges/serv/WhatsApp Image 2024-12-12 at 8.41.06 PM (2).jpeg"
import serv4 from "../imges/serv/WhatsApp Image 2024-12-12 at 8.41.06 PM (3).jpeg"
import serv5 from "../imges/serv/WhatsApp Image 2024-12-12 at 8.41.06 PM.jpeg"

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function Home() {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6, // Show 6 slides at a time
        slidesToScroll: 1, // Scroll 8 slides per action
        autoplay: true,
        autoplaySpeed: 2000, // Autoplay every 2 seconds
        responsive: [
            {
                breakpoint: 1024, // Adjust for medium screens
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 768, // Adjust for small screens
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
        ],
    };
    return (
        <>
            <header id="header" class="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charset="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                    <title>Together-V Home</title>
                    <meta name="description" content="" />
                    <meta name="keywords" content="" />

                    <link href="assets/img/favicon.png" rel="icon" />
                    <link href="assets/img/apple-touch-icon.png" rel="apple-touch-icon" />

                    <link href="https://fonts.googleapis.com" rel="preconnect" />
                    <link href="https://fonts.gstatic.com" rel="preconnect" crossorigin />
                    <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />

                    <link href="assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet" />
                    <link href="assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet" />
                    <link href="assets/vendor/aos/aos.css" rel="stylesheet" />
                    <link href="assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet" />
                    <link href="assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet" />

                </Helmet>
                <header id="header" class="header d-flex align-items-center fixed-top">
                    <div class="container-fluid container-xl position-relative d-flex align-items-center">

                        <a href="index.html" class="logo d-flex align-items-center me-auto w-50">

                            <img src={hero} alt="logo" />
                        </a>

                        <nav id="navmenu" class="navmenu">
                            <ul class=" m-auto" >
                                <li><a href="#hero" class="active">Home<br /></a></li>
                                <li><a href="#about">About</a></li>
                                <li><a href="#values">Why Choose us</a></li>
                                <li><a href="#services">Services</a></li>
                                {/* <li><a href="#testimonials">Testimonials</a></li>
                                <li><a href="#recent-posts">Blogs</a></li> */}
                                {/* <li class="dropdown"><a href="#"><span>Dropdown</span> <i class="bi bi-chevron-down toggle-dropdown"></i></a>
                                    <ul>
                                        <li><a href="#">Dropdown 1</a></li>
                                        <li class="dropdown"><a href="#"><span>Deep Dropdown</span> <i class="bi bi-chevron-down toggle-dropdown"></i></a>
                                            <ul>
                                                <li><a href="#">Deep Dropdown 1</a></li>
                                                <li><a href="#">Deep Dropdown 2</a></li>
                                                <li><a href="#">Deep Dropdown 3</a></li>
                                                <li><a href="#">Deep Dropdown 4</a></li>
                                                <li><a href="#">Deep Dropdown 5</a></li>
                                            </ul>
                                        </li>
                                        <li><a href="#">Dropdown 2</a></li>
                                        <li><a href="#">Dropdown 3</a></li>
                                        <li><a href="#">Dropdown 4</a></li>
                                    </ul>
                                </li>
                                <li class="listing-dropdown"><a href="#"><span>Listing Dropdown</span> <i class="bi bi-chevron-down toggle-dropdown"></i></a>
                                    <ul>
                                        <li>
                                            <a href="#">Column 1 link 1</a>
                                            <a href="#">Column 1 link 2</a>
                                            <a href="#">Column 1 link 3</a>
                                        </li>
                                        <li>
                                            <a href="#">Column 2 link 1</a>
                                            <a href="#">Column 2 link 2</a>
                                            <a href="#">Column 3 link 3</a>
                                        </li>
                                        <li>
                                            <a href="#">Column 3 link 1</a>
                                            <a href="#">Column 3 link 2</a>
                                            <a href="#">Column 3 link 3</a>
                                        </li>
                                        <li>
                                            <a href="#">Column 4 link 1</a>
                                            <a href="#">Column 4 link 2</a>
                                            <a href="#">Column 4 link 3</a>
                                        </li>
                                        <li>
                                            <a href="#">Column 5 link 1</a>
                                            <a href="#">Column 5 link 2</a>
                                            <a href="#">Column 5 link 3</a>
                                        </li>
                                    </ul>
                                </li> */}
                                <li><a href="#contact">Contact</a></li>
                            </ul>
                            <i class="mobile-nav-toggle d-xl-none bi bi-list"></i>
                        </nav>

                        <Link class="btn-getstarted flex-md-shrink-0 redcol" to="/login">Login</Link>

                    </div>
                </header>
            </header>


            <main class="main">

                <section id="hero" class="hero section">

                    <div class="container">
                        <div class="row gy-4">
                            <div class="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
                                <h2 data-aos="fade-up">Hello We are</h2>
                                <h1 data-aos="fade-up">Together-V</h1>
                                <p data-aos="fade-up" data-aos-delay="100">At Together, we don’t just market your business; we build meaningful connections that
                                    make your brand unforgettable. Whether you’re looking to enhance your online
                                    presence, boost engagement, or scale your business, we’re here to help—together.
                                </p>
                                <div class="d-flex flex-column flex-md-row" data-aos="fade-up" data-aos-delay="200">
                                    <Link to="/login" class="btn-get-started ">Login  <FaArrowRightLong /></Link>
                                </div>
                            </div>
                            <div class="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-out">
                                <img src={hero1} class="img-fluid animated" alt="" />
                            </div>
                        </div>
                    </div>

                </section>
                <section id="about" class="about section">

                    <div class="container" data-aos="fade-up">
                        <div class="row gx-0">

                            <div class="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                                <div class="content">
                                    <h3>Who We Are</h3>
                                    <h2>At Together, we believe in the power of collaboration to drive digital success. As a
                                        specialized digital marketing agency, we partner with businesses to craft innovative,
                                        results-driven strategies that amplify their online presence, engage their audiences, and
                                        achieve measurable growth.</h2>

                                    <div class="text-center text-lg-start">
                                        <a href="#" class="btn-read-more redcol d-inline-flex align-items-center justify-content-center align-self-center">
                                            <span>Read More</span>
                                            <FaArrowRightLong />
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                                <img src={aboutt} class="img-fluid" alt="" />
                            </div>

                        </div>
                    </div>

                </section>
                <section id="alt-features" class="alt-features section">

                    <div class="container">

                        <div class="row gy-5">
                            <div class="col-xl-5 d-flex align-items-center order-1 order-xl-1" data-aos="fade-up" data-aos-delay="100">
                                <img src={alt} class="img-fluid" alt="" />
                            </div>
                            <div class="col-xl-7 d-flex order-2 order-xl-2" data-aos="fade-up" data-aos-delay="200">

                                <div class="row align-self-center gy-5">

                                    <div class="col-md-12 icon-box">
                                        <FaAward />
                                        <div>
                                            <h4>Our Vision</h4>
                                            <p>Our Vision
                                                To be a leading digital marketing partner, helping businesses around the globe build
                                                meaningful, lasting relationships with their audiences.
                                            </p>
                                        </div>
                                    </div>


                                    <div class="col-md-12 icon-box">
                                        <i class="bi bi-patch-check"></i>
                                        <div>
                                            <h4>Our Mission</h4>
                                            <p>Our Mission To bring businesses and their customers closer together by crafting creative, datadriven digital marketing strategies that inspire connection and success.</p>
                                        </div>
                                    </div>

                                </div>

                            </div>



                        </div>

                    </div>

                </section>


                <section id="values" class="values section">

                    <div class="container section-title" data-aos="fade-up">
                        <h2 className="">Our Values</h2>
                        <p>Why Choose Together<br /></p>
                    </div>

                    <div class="container">

                        <div class="row gy-4">

                            <div class="col-lg-3" data-aos="fade-up" data-aos-delay="100">
                                <div class="card">
                                    <img src={val1} class="img-fluid" alt="" />
                                    <h3>Collaborative Partnership </h3>
                                    <p>We work hand-in-hand with you to bring your vision to
                                        life.</p>
                                </div>
                            </div>

                            <div class="col-lg-3" data-aos="fade-up" data-aos-delay="200">
                                <div class="card">
                                    <img src={val2} class="img-fluid" alt="" />
                                    <h3>Tailored Strategies</h3>
                                    <p> Every solution is customized to meet your unique business
                                        needs.</p>
                                </div>
                            </div>

                            <div class="col-lg-3" data-aos="fade-up" data-aos-delay="300">
                                <div class="card">
                                    <img src={val3} class="img-fluid" alt="" />
                                    <h3>Expertise You Can Trust </h3>
                                    <p>Our experienced team uses the latest tools and trends to
                                        deliver exceptional results.</p>
                                </div>
                            </div>
                            <div class="col-lg-3" data-aos="fade-up" data-aos-delay="300">
                                <div class="card">
                                    <img src={val3} class="img-fluid" alt="" />
                                    <h3>Clear Communication</h3>
                                    <p>We keep you informed every step of the way with
                                        transparent updates and report</p>
                                </div>
                            </div>
                        </div>

                    </div>

                </section>




                <section id="services" class="services section">

                    <div class="container section-title" data-aos="fade-up">
                        <h2>Services</h2>
                        <p>Check Our Services<br /></p>
                    </div>

                    <div class="container">

                        <div class="row gy-4">

                            <div class="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div class="service-item item-cyan position-relative">
                                    <img className="img-home w-22" src={serv3} alt="" />

                                    <h3>Direct Selling business
                                    </h3>
                                    <p> <ul>
                                        <li>Access to a network of skilled affiliates ready to promote your products or services.</li>
                                        <li>Data-driven strategies to optimise results and maximise ROI.</li>
                                        <li>Transparent performance tracking and reporting tools.</li>
                                        <li>Holding many leadership trainings and effective conferences for the success of
                                            business</li>
                                    </ul>
                                    </p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="200">
                                <div class="service-item item-orange position-relative">
                                    <img className="img-home w-22" src={serv4} alt="" />

                                    <h3>Affiliates business</h3>
                                    <p>
                                        <ul>
                                            <li> A curated selection of high-quality products and services to promote.
                                            </li>
                                            <li> Competitive commission rates and flexible payment options.
                                            </li>
                                            <li> Comprehensive training and resources to enhance your marketing skills.
                                            </li>
                                            <li>As a merchant, you can upload your products to our website and provide you with an
                                                integrated marketing team to market these products and make profits
                                            </li>
                                            <li> As a marketer, you can market all our products and get the largest commissions and
                                                provide you with professional methods of marketing through a range of innovative
                                                trainings
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                                <div class="service-item item-teal position-relative">
                                    <img className="img-home w-22" src={serv1} alt="" />

                                    <h3>Social Media Marketing
                                    </h3>
                                    <p>We design campaigns that grow your online presence, foster engagement, and build
                                        brand loyalty</p>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                                <div class="service-item item-red position-relative">
                                    <img className="img-home w-22" src={serv2} alt="" />

                                    <h3>Branding & Strategy
                                    </h3>
                                    <p>We Build a strong brand identity and develop a cohesive marketing strategy tailored to
                                        your business goals ,
                                        Market research and competitor analysis,
                                        Strategic planning and consulting
                                    </p>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
                                <div class="service-item item-indigo position-relative">
                                    <img className="img-home w-22" src={serv5} alt="" />
                                    <h3>Analytics & Reporting
                                    </h3>
                                    <p>Understand your performance and make data-driven decisions with our in-depth
                                        analytics and reporting,
                                        Performance dashboards,
                                        Insights for continuous improvement</p>
                                </div>
                            </div>


                        </div>

                    </div>

                </section>


                {/* <section id="testimonials" class="testimonials section">


                    <div class="container section-title" data-aos="fade-up">
                        <h2>Testimonials</h2>
                        <p>What they are saying about us<br /></p>
                    </div>

                    <div class="container" data-aos="fade-up" data-aos-delay="100">

                        <div class="swiper init-swiper">

                            <div class="swiper-wrapper">

                                <div class="swiper-slide">
                                    <div class="testimonial-item">
                                        <div class="stars">
                                            <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                                        </div>
                                        <p>
                                            Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
                                        </p>
                                        <div class="profile mt-auto">
                                            <img src="assets/img/testimonials/testimonials-1.jpg" class="testimonial-img" alt="" />
                                            <h3>Saul Goodman</h3>
                                            <h4>Ceo &amp; Founder</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="swiper-slide">
                                    <div class="testimonial-item">
                                        <div class="stars">
                                            <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                                        </div>
                                        <p>
                                            Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.
                                        </p>
                                        <div class="profile mt-auto">
                                            <img src="assets/img/testimonials/testimonials-2.jpg" class="testimonial-img" alt="" />
                                            <h3>Sara Wilsson</h3>
                                            <h4>Designer</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="swiper-slide">
                                    <div class="testimonial-item">
                                        <div class="stars">
                                            <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                                        </div>
                                        <p>
                                            Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.
                                        </p>
                                        <div class="profile mt-auto">
                                            <img src="assets/img/testimonials/testimonials-3.jpg" class="testimonial-img" alt="" />
                                            <h3>Jena Karlis</h3>
                                            <h4>Store Owner</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="swiper-slide">
                                    <div class="testimonial-item">
                                        <div class="stars">
                                            <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                                        </div>
                                        <p>
                                            Fugiat enim eram quae cillum dolore dolor amet nulla culpa multos export minim fugiat minim velit minim dolor enim duis veniam ipsum anim magna sunt elit fore quem dolore labore illum veniam.
                                        </p>
                                        <div class="profile mt-auto">
                                            <img src="assets/img/testimonials/testimonials-4.jpg" class="testimonial-img" alt="" />
                                            <h3>Matt Brandon</h3>
                                            <h4>Freelancer</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="swiper-slide">
                                    <div class="testimonial-item">
                                        <div class="stars">
                                            <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                                        </div>
                                        <p>
                                            Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat legam esse veniam culpa fore nisi cillum quid.
                                        </p>
                                        <div class="profile mt-auto">
                                            <img src="assets/img/testimonials/testimonials-5.jpg" class="testimonial-img" alt="" />
                                            <h3>John Larson</h3>
                                            <h4>Entrepreneur</h4>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="swiper-pagination"></div>
                        </div>

                    </div>

                </section> */}


                {/* <section id="clients" class="clients section">

                    <div class="container section-title" data-aos="fade-up">
                        <h2>Clients</h2>
                        <p>We work with best clients<br /></p>
                    </div>

                    <div className="container" data-aos="fade-up" data-aos-delay="100">
                        <Slider {...settings}>
                            <div className="swiper-slide me-3">
                                <img src={cli1} className="img-fluid mx-2" alt="Client 1" />
                            </div>
                            <div className="swiper-slide me-3">
                                <img src={cli2} className="img-fluid mx-2" alt="Client 2" />
                            </div>
                            <div className="swiper-slide me-3">
                                <img src={cli3} className="img-fluid mx-2" alt="Client 3" />
                            </div>
                            <div className="swiper-slide me-3">
                                <img src={cli4} className="img-fluid mx-2" alt="Client 4" />
                            </div>
                            <div className="swiper-slide me-3">
                                <img src={cli5} className="img-fluid mx-2" alt="Client 5" />
                            </div>
                            <div className="swiper-slide me-3">
                                <img src={cli6} className="img-fluid mx-2" alt="Client 6" />
                            </div>
                            <div className="swiper-slide me-3">
                                <img src={cli7} className="img-fluid mx-2" alt="Client 7" />
                            </div>
                            <div className="swiper-slide me-3">
                                <img src={cli8} className="img-fluid mx-2" alt="Client 8" />
                            </div>
                        </Slider>
                    </div>

                </section> */}
                {/* <section id="recent-posts" class="recent-posts section">


                    <div class="container section-title" data-aos="fade-up">
                        <h2>Recent Posts</h2>
                        <p>Recent posts form our Blog</p>
                    </div>

                    <div class="container">

                        <div class="row gy-5">

                            <div class="col-xl-4 col-md-6">
                                <div class="post-item position-relative h-100" data-aos="fade-up" data-aos-delay="100">

                                    <div class="post-img position-relative overflow-hidden">
                                        <img src={pho1} class="img-fluid" alt="" />
                                        <span class="post-date">December 12</span>
                                    </div>

                                    <div class="post-content d-flex flex-column">

                                        <h3 class="post-title">Eum ad dolor et. Autem aut fugiat debitis</h3>

                                        <div class="meta d-flex align-items-center">
                                            <div class="d-flex align-items-center">
                                                <i class="bi bi-person"></i> <span class="ps-2">Julia Parker</span>
                                            </div>
                                            <span class="px-3 text-black-50">/</span>
                                            <div class="d-flex align-items-center">
                                                <i class="bi bi-folder2"></i> <span class="ps-2">Politics</span>
                                            </div>
                                        </div>

                                        <hr />

                                        <a href="#" class="readmore stretched-link"><span>Read More</span><i class="bi bi-arrow-right"></i></a>

                                    </div>

                                </div>
                            </div>

                            <div class="col-xl-4 col-md-6">
                                <div class="post-item position-relative h-100" data-aos="fade-up" data-aos-delay="200">

                                    <div class="post-img position-relative overflow-hidden">
                                        <img src={pho2} class="img-fluid" alt="" />
                                        <span class="post-date">July 17</span>
                                    </div>

                                    <div class="post-content d-flex flex-column">

                                        <h3 class="post-title">Et repellendus molestiae qui est sed omnis</h3>

                                        <div class="meta d-flex align-items-center">
                                            <div class="d-flex align-items-center">
                                                <i class="bi bi-person"></i> <span class="ps-2">Mario Douglas</span>
                                            </div>
                                            <span class="px-3 text-black-50">/</span>
                                            <div class="d-flex align-items-center">
                                                <i class="bi bi-folder2"></i> <span class="ps-2">Sports</span>
                                            </div>
                                        </div>

                                        <hr />

                                        <a href="#" class="readmore stretched-link"><span>Read More</span><i class="bi bi-arrow-right"></i></a>

                                    </div>

                                </div>
                            </div>

                            <div class="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                                <div class="post-item position-relative h-100">

                                    <div class="post-img position-relative overflow-hidden">
                                        <img src={pho3} class="img-fluid" alt="" />
                                        <span class="post-date">September 05</span>
                                    </div>

                                    <div class="post-content d-flex flex-column">

                                        <h3 class="post-title">Quia assumenda est et veritati tirana ploder</h3>

                                        <div class="meta d-flex align-items-center">
                                            <div class="d-flex align-items-center">
                                                <i class="bi bi-person"></i> <span class="ps-2">Lisa Hunter</span>
                                            </div>
                                            <span class="px-3 text-black-50">/</span>
                                            <div class="d-flex align-items-center">
                                                <i class="bi bi-folder2"></i> <span class="ps-2">Economics</span>
                                            </div>
                                        </div>

                                        <hr />

                                        <a href="#" class="readmore stretched-link"><span>Read More</span><i class="bi bi-arrow-right"></i></a>

                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>

                </section> */}


                <section id="contact" class="contact section">


                    <div class="container section-title" data-aos="fade-up">
                        <h2>Contact</h2>
                        <p>Contact Us</p>
                        <p>Ready to create something extraordinary? Contact us today to start your digital
                            marketing journey .</p>
                    </div>

                    <div class="container" data-aos="fade-up" data-aos-delay="100">

                        <div class="row gy-4">

                            <div class="col-lg-4">

                                <div class="row gy-4">


                                    <div class="col-md-12">
                                        <div class="info-item" data-aos="fade" data-aos-delay="300">
                                            <FaPhoneAlt />
                                            <h3>Call Us</h3>
                                            <p>+20 1003726155</p>
                                            <p>+20 1156360555</p>
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="info-item" data-aos="fade" data-aos-delay="400">
                                            <MdEmail />
                                            <h3>Email Us</h3>
                                            <p>together.v.eg@gmail.com</p>
                                        </div>
                                    </div>



                                </div>

                            </div>

                            <div class="col-lg-8">
                                <form action="forms/contact.php" method="post" class="php-email-form" data-aos="fade-up" data-aos-delay="200">
                                    <div class="row gy-4">

                                        <div class="col-md-6">
                                            <input type="text" name="name" class="form-control" placeholder="Your Name" required="" />
                                        </div>

                                        <div class="col-md-6 ">
                                            <input type="email" class="form-control" name="email" placeholder="Your Email" required="" />
                                        </div>

                                        <div class="col-12">
                                            <input type="text" class="form-control" name="subject" placeholder="Subject" required="" />
                                        </div>

                                        <div class="col-12">
                                            <textarea class="form-control" name="message" rows="6" placeholder="Message" required=""></textarea>
                                        </div>

                                        <div class="col-12 text-center">
                                            <div class="loading">Loading</div>
                                            <div class="error-message"></div>
                                            <div class="sent-message">Your message has been sent. Thank you!</div>

                                            <button type="submit" className="redcol">Send Message</button>
                                        </div>

                                    </div>
                                </form>
                            </div>

                        </div>

                    </div>

                </section>

            </main>


        </>
    );
}

export default Home;

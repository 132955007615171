import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { AdminContext } from "../../Context/AdminContext"
import { jsPDF } from "jspdf";
import logoo from "../../imges/logo.png"
import { FiAlignJustify } from "react-icons/fi";
import { BallTriangle } from 'react-loader-spinner'

function WithDrawRequest() {
    const queryClient = useQueryClient();
    let { logOut } = useContext(AdminContext)
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768); // Sidebar open for desktop by default
    let navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768); // Open sidebar on desktop, close on mobile
        };

        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    // Fetch all pending withdraw requests
    function getAllTransfers() {
        return axios.get(`https://together-v.com/api/v1/agent/AgentsTransferHistory`, {
            withCredentials: true,
        });
    }

    const { data, isLoading } = useQuery("getAllTransfers", getAllTransfers);

    const downloadPDF = () => {
        const doc = new jsPDF();
        let y = 10;

        // Title
        doc.setFontSize(16);
        doc.text("All Transfers", 10, y);
        y += 10;

        // Table Headers
        doc.setFontSize(12);
        doc.text("No.", 10, y);
        doc.text("From", 30, y);
        doc.text("Amount", 100, y);
        doc.text("To", 140, y);
        y += 10;

        // Table Data
        data?.data.data.forEach((agent, index) => {
            doc.text(String(index + 1), 10, y);
            doc.text(`${agent.from.firstName} ${agent.from.lastName}`, 30, y);
            doc.text(`${agent.request}`, 100, y);
            doc.text(`${agent.to.firstName} ${agent.to.lastName}`, 140, y);
            y += 10;
        });

        // Save PDF
        doc.save("Transfers.pdf");
    };

    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                    <title>Transfers</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <header id="header" className="header fixed-top d-flex align-items-center">
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <img
                                src={logoo}
                                alt="Logo"
                                className="logo d-flex align-items-center w-35"
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate('/DashboardAdmin')}
                            />
                        </div>
                        <h2 className="menu-icon" onClick={toggleSidebar}>
                            <FiAlignJustify />
                        </h2>
                    </div>
                </header>
            </header>

            <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/DashboardAdmin">
                            <i className="bi bi-grid"></i>
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Agents">
                            <i className="bi bi-grid"></i>
                            <span>All Agents</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEpin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Epin</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EPin">
                            <i className="bi bi-grid"></i>
                            <span>E-Pins</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EpinHistory">
                            <i className="bi bi-grid"></i>
                            <span>E-Pins History</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddProduct">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Product</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyTreeAdmin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>My Tree</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEvent">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Event</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProducts">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>All Products</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDrawRequest">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Withdraw Requests</span>
                        </Link>
                    </li>

                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Reports</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/MyTransfers">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>My Transfers</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllRequestsProducts">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Purchase</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/TransferRequests">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Transfers</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllWithDrawsAdmin">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Withdraws</span>
                                </Link>
                            </li>
                        </ul>
                    </li>


                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Filtration">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Filtration</span>
                        </Link>
                    </li>
                    <li className="nav-heading">Pages</li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllContactUs">
                            <i className="bi bi-person"></i>
                            <span>All Contact</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ProfileAdmin">
                            <i className="bi bi-person"></i>
                            <span>Profile</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" onClick={() => logOut()}>
                            <i className="bi bi-person"></i>
                            <span>Log Out</span>
                        </Link>
                    </li>
                </ul>
            </aside>

            <section>
                <div id="main" className="bg-light-2">
                    <div className="pagetitle">
                        <h1>All Transfers</h1>
                        <button className="btn btn-primary my-3" onClick={downloadPDF}>
                            Download as PDF
                        </button>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="index.html">Home</a>
                                </li>
                                <li className="breadcrumb-item">Dashboard</li>
                                <li className="breadcrumb-item active">All Transfers </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="body genealogy-body genealogy-scroll">
                        <div
                            className="tab-pane fade active show"
                            id="all-booking"
                            role="tabpanel"
                        >
                            {isLoading ? <div className="'w-100 py-5 d-flex justify-content-center">
                                <BallTriangle
                                    height={100}
                                    width={100}
                                    radius={5}
                                    color="#4fa94d"
                                    ariaLabel="ball-triangle-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                            </div> : <>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h2>All Transfers </h2>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>From</th>
                                                        <th>id</th>
                                                        <th>Amount</th>
                                                        <th>To</th>
                                                        <th>id</th>
                                                        <th>Created At</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data?.data.data.map((agent, index) => (
                                                        <tr key={agent._id}>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                {agent.from
                                                                    ? `${agent.from.firstName} ${agent.from.lastName}`
                                                                    : "Unknown"}
                                                            </td>
                                                            <td>{agent.from?._id || "N/A"}</td>
                                                            <td>{agent.request}</td>
                                                            <td>
                                                                {agent.to
                                                                    ? `${agent.to.firstName} ${agent.to.lastName}`
                                                                    : "Unknown"}
                                                            </td>
                                                            <td>{agent.to?._id || "N/A"}</td>
                                                            <td>{agent.createdAt.split("T")[0]}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </>}


                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

export default WithDrawRequest;

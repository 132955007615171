import axios from "axios";
import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { AdminContext } from "../../Context/AdminContext";
import logoo from "../../imges/logo.png";
import { FiAlignJustify } from "react-icons/fi";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Icons for eye and eye-slash
function PrivacyPolicy({ onAgreeChange }) {
    return (
        <div className="privacy-policy mt-5 p-4 bg-white shadow">
            <h2>Privacy Policy</h2>
            <p>
                We are <strong>(TOGETHER)</strong>. We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website <a href="https://together-v.com" target="_blank" rel="noopener noreferrer">Together-v.com</a>.
            </p>
            <p>
                Please read this policy carefully. If you do not agree with the terms of this policy, please do not access the Website.
            </p>

            <h3>1. Information We Collect</h3>
            <p>We may collect information about you in several ways, including:</p>
            <h4>a) Personal Information</h4>
            <p>
                When you interact with our Website, we may collect personally identifiable information, such as:
                <ul>
                    <li>Your name</li>
                    <li>Email address</li>
                    <li>Phone number</li>
                </ul>
            </p>
            <h4>b) Non-Personal Information</h4>
            <p>
                We also collect non-personal information, such as:
                <ul>
                    <li>IP address</li>
                    <li>Browser type and version</li>
                    <li>Device information</li>
                    <li>Pages visited on our Website</li>
                    <li>Date and time of visits</li>
                    <li>Referring URL</li>
                </ul>
            </p>
            <h4>c) Cookies and Tracking Technologies</h4>
            <p>
                We use cookies, beacons, and other tracking technologies to improve your experience on our Website. These technologies help us analyze user behavior, remember your preferences, and deliver personalized content and advertisements.
            </p>

            <h3>2. How We Use Your Information</h3>
            <p>We use the information we collect to:</p>
            <ul>
                <li>Provide, operate, and maintain the Website.</li>
                <li>Communicate with you about our services, promotions, or updates.</li>
                <li>Personalize your experience and improve our services.</li>
                <li>Process transactions, if applicable.</li>
                <li>Comply with legal and regulatory obligations.</li>
            </ul>

            <h3>3. Sharing Your Information</h3>
            <p>
                We do not sell your personal information. However, we may share your information with:
            </p>
            <ul>
                <li>
                    <strong>Service Providers:</strong> Third-party vendors who assist with operations like hosting, payment processing, or marketing.
                </li>
                <li>
                    <strong>Legal Authorities:</strong> When required to comply with legal obligations or protect our rights.
                </li>
            </ul>

            <h3>4. Your Rights and Choices</h3>
            <p>You have the following rights regarding your personal information:</p>
            <ul>
                <li>
                    <strong>Access:</strong> Request a copy of the information we hold about you.
                </li>
                <li>
                    <strong>Correction:</strong> Update or correct inaccurate information.
                </li>
            </ul>

            <h3>5. Data Security</h3>
            <p>
                We implement appropriate technical and organizational measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet is completely secure, and we cannot guarantee absolute security.
            </p>

            <h3>6. Updates to This Policy</h3>
            <p>
                We may update this Privacy Policy from time to time. The updated policy will be posted on this page with the "Effective Date" revised. We encourage you to review this policy periodically.
            </p>

            <p>
                By using our Website, you consent to this Privacy Policy and agree to its terms.
            </p>
            <div className="form-check mt-3">
                <input
                    type="checkbox"
                    id="agreePolicy"
                    className="form-check-input"
                    onChange={(e) => onAgreeChange(e.target.checked)}
                />
                <label htmlFor="agreePolicy" className="form-check-label">
                    I have read and agree to the Privacy Policy
                </label>
            </div>
        </div>
    );
}

function AddAgentAdmin() {
    let { logOut } = useContext(AdminContext);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [newAgentDetails, setNewAgentDetails] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showWalletPassword, setShowWalletPassword] = useState(false);
    const [showConfirmWalletPassword, setShowConfirmWalletPassword] = useState(false);
    const [isPolicyAgreed, setIsPolicyAgreed] = useState(false);

    let navigate = useNavigate();

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    // Extract parentId and position from URL
    const [searchParams] = useSearchParams();
    const parentId = searchParams.get("parentId") || "";
    const position = searchParams.get("position") || ""; // Default to empty string if not provided

    const formik = useFormik({
        initialValues: {
            firstName: "",
            secondName: "",
            thirdName: "",
            lastName: "",
            email: "",
            nationalId: "",
            phone: "",
            address: "",
            country: "",
            governorate: "",
            city: "",
            inheritorName: "",
            grandfatherInheritorName: "",
            zipCode: "",
            refId: parentId,
            direction: position || "", // Use position if provided, otherwise empty
            password: "",
            confirmPassword: "",
            walletPassword: "",
            confirmWalletPassword: "",
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required("First name is required"),
            secondName: Yup.string().required("Second name is required"),
            thirdName: Yup.string().required("Third name is required"),
            lastName: Yup.string().required("Last name is required"),
            email: Yup.string().email("Invalid email address").required("Email is required"),
            nationalId: Yup.string()
                .length(14, "National ID must be 14 digits")
                .required("National ID is required"),
            phone: Yup.string().required("Phone number is required"),
            address: Yup.string().required("Address is required"),
            country: Yup.string().required("Country is required"),
            governorate: Yup.string().required("Governorate is required"),
            city: Yup.string().required("City is required"),
            inheritorName: Yup.string().required("Inheritor name is required"),
            grandfatherInheritorName: Yup.string().required("Grandfather inheritor name is required"),
            zipCode: Yup.string().required("ZIP code is required"),
            direction: Yup.string()
                .oneOf(["right1", "right0", "left0", "left1"], "Invalid direction")
                .required("Direction is required"),
            password: Yup.string()
                .min(8, "Password must be at least 8 characters")
                .required("Password is required"),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref("password"), null], "Passwords must match")
                .required("Confirm Password is required"),
            walletPassword: Yup.string()
                .min(8, "Wallet Password must be at least 8 characters")
                .required("Wallet Password is required"),
            confirmWalletPassword: Yup.string()
                .oneOf([Yup.ref("walletPassword"), null], "Wallet Passwords must match")
                .required("Confirm Wallet Password is required"),
        }),
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            const { confirmPassword, confirmWalletPassword, ...submitValues } = values;
            try {
                const token = localStorage.getItem("AgentToken");

                const response = await axios.post(
                    "https://together-v.com/api/v1/agent/register",
                    submitValues,
                    {
                        withCredentials: true,
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                toast.success("New Agent Added Successfully");

                setNewAgentDetails({
                    firstName: values.firstName,
                    secondName: values.secondName,
                    thirdName: values.thirdName,
                    lastName: values.lastName,
                    email: values.email,
                });

                formik.resetForm();
            } catch (error) {
                toast.error(error.response?.data?.message || "Failed to add agent");
                setErrors({ api: error.response?.data?.message || "Signup failed" });
            } finally {
                setSubmitting(false);
            }
        },
    });

    return (
        <>
            <header id="header" class="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charset="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                    <title>Add E-Pin</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <header id="header" class="header fixed-top d-flex align-items-center">
                    <div class="d-flex align-items-center justify-content-between">
                        <div>
                            <img
                                src={logoo}
                                alt="Logo"
                                className="logo d-flex align-items-center w-35"
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate('/DashboardAdmin')}
                            />
                        </div>                        <h2 className="menu-icon" onClick={toggleSidebar}>
                            <FiAlignJustify />
                        </h2>
                    </div>
                </header>
            </header>


            <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/DashboardAdmin">
                            <i className="bi bi-grid"></i>
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Agents">
                            <i className="bi bi-grid"></i>
                            <span>All Agents</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEpin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Epin</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EPin">
                            <i className="bi bi-grid"></i>
                            <span>E-Pins</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EpinHistory">
                            <i className="bi bi-grid"></i>
                            <span>E-Pins History</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddProduct">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Product</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyTreeAdmin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>My Tree</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEvent">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Event</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProducts">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>All Products</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDrawRequest">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Withdraw Requests</span>
                        </Link>
                    </li>

                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Reports</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/MyTransfers">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>My Transfers</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllRequestsProducts">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Purchase</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/TransferRequests">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Transfers</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllWithDrawsAdmin">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Withdraws</span>
                                </Link>
                            </li>
                        </ul>
                    </li>


                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Filtration">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Filtration</span>
                        </Link>
                    </li>
                    <li className="nav-heading">Pages</li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllContactUs">
                            <i className="bi bi-person"></i>
                            <span>All Contact</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ProfileAdmin">
                            <i className="bi bi-person"></i>
                            <span>Profile</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" onClick={() => logOut()}>
                            <i className="bi bi-person"></i>
                            <span>Log Out</span>
                        </Link>
                    </li>
                </ul>
            </aside>



            <div id="main" className="bg-light-2">
                <div className="pagetitle">
                    <h1>Add Agent</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Add Agent</li>
                        </ol>
                    </nav>
                </div>
                <div className="p-4 rounded-1 bg-white shadow-3">
                    <form onSubmit={formik.handleSubmit}>
                        {Object.keys(formik.initialValues).map((key) => (
                            <div key={key} className="mb-4">
                                <label className="form-label" htmlFor={key}>
                                    {key === "refId" ? "Parent ID" : key.charAt(0).toUpperCase() + key.slice(1)}
                                </label>
                                {key === "direction" && !position ? (
                                    <select
                                        id={key}
                                        className={`form-control ${formik.touched[key] && formik.errors[key] ? "is-invalid" : ""
                                            }`}
                                        {...formik.getFieldProps(key)}
                                    >
                                        <option value="">Select Direction</option>
                                        <option value="right1">Right 1</option>
                                        <option value="right0">Right 0</option>
                                        <option value="left0">Left 0</option>
                                        <option value="left1">Left 1</option>
                                    </select>
                                ) : key === "password" || key === "confirmPassword" || key === "walletPassword" || key === "confirmWalletPassword" ? (
                                    <div className="input-group">
                                        <input
                                            type={
                                                (key === "password" && !showPassword) ||
                                                    (key === "confirmPassword" && !showConfirmPassword) ||
                                                    (key === "walletPassword" && !showWalletPassword) ||
                                                    (key === "confirmWalletPassword" && !showConfirmWalletPassword)
                                                    ? "password"
                                                    : "text"
                                            }
                                            id={key}
                                            className={`form-control ${formik.touched[key] && formik.errors[key] ? "is-invalid" : ""
                                                }`}
                                            {...formik.getFieldProps(key)}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary"
                                            onClick={() => {
                                                if (key === "password") setShowPassword(!showPassword);
                                                else if (key === "confirmPassword") setShowConfirmPassword(!showConfirmPassword);
                                                else if (key === "walletPassword") setShowWalletPassword(!showWalletPassword);
                                                else if (key === "confirmWalletPassword") setShowConfirmWalletPassword(!showConfirmWalletPassword);
                                            }}
                                        >
                                            {((key === "password" && showPassword) ||
                                                (key === "confirmPassword" && showConfirmPassword) ||
                                                (key === "walletPassword" && showWalletPassword) ||
                                                (key === "confirmWalletPassword" && showConfirmWalletPassword)) ? (
                                                <FaEyeSlash />
                                            ) : (
                                                <FaEye />
                                            )}
                                        </button>
                                    </div>
                                ) : (
                                    <input
                                        type="text"
                                        id={key}
                                        className={`form-control ${formik.touched[key] && formik.errors[key] ? "is-invalid" : ""
                                            }`}
                                        {...formik.getFieldProps(key)}
                                    />
                                )}
                                {formik.touched[key] && formik.errors[key] ? (
                                    <div className="invalid-feedback">{formik.errors[key]}</div>
                                ) : null}
                            </div>
                        ))}
                        <PrivacyPolicy onAgreeChange={setIsPolicyAgreed} />

                        <button
                            type="submit"
                            className="btn btn-primary mt-3"
                            disabled={!isPolicyAgreed}
                        >
                            {formik.isSubmitting ? "Adding..." : "Add Agent"}
                        </button>
                    </form>

                    {newAgentDetails && (
                        <div className="mt-5 p-4 border rounded bg-light">
                            <h4>New Agent Added:</h4>
                            <p><strong>First Name:</strong> {newAgentDetails.firstName}</p>
                            <p><strong>Second Name:</strong> {newAgentDetails.secondName}</p>
                            <p><strong>Third Name:</strong> {newAgentDetails.thirdName}</p>
                            <p><strong>Last Name:</strong> {newAgentDetails.lastName}</p>
                            <p><strong>Email:</strong> {newAgentDetails.email}</p>
                        </div>
                    )}
                </div>
            </div>




        </>
    );
}

export default AddAgentAdmin;

import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { AgentContext } from "../../Context/AgentContext";
import gold from "../../imges/gold.jpg";
import red from "../../imges/red.png";
import silver from "../../imges/silver.avif";
import bronze from "../../imges/bronze.jpg";
import logoo from "../../imges/logo.png";
import { FiAlignJustify } from "react-icons/fi";
import { BallTriangle } from "react-loader-spinner";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import toast from "react-hot-toast";
import { IoIosSettings } from "react-icons/io";

function Tree() {
    const { logOut } = useContext(AgentContext);
    const [visibility, setVisibility] = useState({});
    const [selectedMember, setSelectedMember] = useState(null); // For modal
    const navigate = useNavigate();
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768); // Sidebar open for desktop by default

    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768); // Open sidebar on desktop, close on mobile
        };

        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    function getAgentTree() {
        return axios.get(`https://together-v.com/api/v1/agent/getAgentTree`, {
            withCredentials: true,
        });
    }

    const { data, isLoading } = useQuery("getAgentTree", getAgentTree);

    const toggleVisibility = (id) => {
        setVisibility((prev) => ({
            ...prev,
            [id]: !prev[id], // Toggle visibility for this node
        }));
    };

    const fetchMemberDetails = async (memberId) => {
        try {
            const response = await axios.get(
                `https://together-v.com/api/v1/agent/getAgentByAdmin/${memberId}`,
                { withCredentials: true }
            );
            setSelectedMember(response.data.data);
        } catch (error) {
            toast.error("Failed to fetch member details.");
        }
    };

    const renderMember = (member) => {
        if (!member) return null; // Stop recursion if no member is provided

        const getImageByLevel = (level) => {
            switch (level) {
                case "red":
                    return red;
                case "gold":
                    return gold;
                case "silver":
                    return silver;
                case "bronze":
                    return bronze;
                default:
                    return "assets/img/default.jpg";
            }
        };

        const navigateToAddAgent = (parentId, position) => {
            navigate(`/addagent?parentId=${parentId}&position=${position}`);
        };

        return (
            <li key={member._id} style={{ listStyleType: "none" }}>
                {/* Current Member */}
                <div className="member-view-box" style={{ textAlign: "center", marginBottom: "20px" }}>
                    <div className="member-image">
                        <img
                            src={getImageByLevel(member.level)}
                            alt={member.level}
                            style={{ cursor: "pointer", width: "50px", height: "50px" }}
                            onClick={() => toggleVisibility(member._id)} // Toggle visibility
                        />
                        <div className="member-details mt-2">
                            <h3
                                className="fnt-10px"
                                style={{ cursor: "pointer", color: "blue" }}
                                onClick={() => fetchMemberDetails(member._id)} // Fetch details
                            >
                                01: {member._id}
                            </h3>
                        </div>
                    </div>

                    {/* Left and Right Children */}
                    {visibility[member._id] && (
                        <div
                            className="children-container"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            {/* Left Child */}
                            {member.leftChild ? (
                                <div className="child-detail" style={{ textAlign: "center" }}>
                                    <div className="member-image">
                                        <img
                                            src={getImageByLevel(member.level)}
                                            alt="Left Child"
                                            style={{ cursor: "pointer", width: "50px", height: "50px" }}
                                            onClick={() => fetchMemberDetails(member.leftChild)}
                                        />
                                        <div
                                            className="fnt-10px"
                                            style={{ cursor: "pointer", color: "blue" }}
                                            onClick={() => fetchMemberDetails(member.leftChild)}
                                        >
                                            02: {member.leftChild}
                                        </div>
                                        <ul style={{ display: "flex", justifyContent: "center", padding: 0 }}>
                                            {member.leftChild0 ? (
                                                <li style={{ margin: "0 10px" }} className="fnt-10px">
                                                    {renderMember(member.leftChild0)}
                                                </li>
                                            ) : (
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={() => navigateToAddAgent(member.leftChild, "left0")}
                                                >
                                                    +
                                                </button>
                                            )}
                                            {member.rightChild0 ? (
                                                <li style={{ margin: "0 10px" }} className="fnt-10px">
                                                    {renderMember(member.rightChild0)}
                                                </li>
                                            ) : (
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={() => navigateToAddAgent(member.leftChild, "right0")}
                                                >
                                                    +
                                                </button>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            ) : (
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => navigateToAddAgent(member._id, "leftChild")}
                                >
                                    +
                                </button>
                            )}

                            {/* Right Child */}
                            {member.rightChild ? (
                                <div className="child-detail" style={{ textAlign: "center" }}>
                                    <img
                                        src={getImageByLevel(member.level)}
                                        alt="Right Child"
                                        style={{
                                            cursor: "pointer",
                                            width: "50px",
                                            height: "50px",
                                        }}
                                        onClick={() => fetchMemberDetails(member.rightChild)}
                                    />
                                    <div
                                        className="fnt-10px"
                                        style={{ cursor: "pointer", color: "blue" }}
                                        onClick={() => fetchMemberDetails(member.rightChild)}
                                    >
                                        03: {member.rightChild}
                                    </div>
                                    <ul style={{ display: "flex", justifyContent: "center", padding: 0 }}>
                                        {member.leftChild1 ? (
                                            <li style={{ margin: "0 10px" }} className="fnt-10px">
                                                {renderMember(member.leftChild1)}
                                            </li>
                                        ) : (
                                            <button
                                                className="btn btn-primary btn-sm"
                                                onClick={() => navigateToAddAgent(member.rightChild, "left1")}
                                            >
                                                +
                                            </button>
                                        )}
                                        {member.rightChild1 ? (
                                            <li style={{ margin: "0 10px" }} className="fnt-10px">
                                                {renderMember(member.rightChild1)}
                                            </li>
                                        ) : (
                                            <button
                                                className="btn btn-primary btn-sm"
                                                onClick={() => navigateToAddAgent(member.rightChild, "right1")}
                                            >
                                                +
                                            </button>
                                        )}
                                    </ul>
                                </div>
                            ) : (
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => navigateToAddAgent(member._id, "rightChild")}
                                >
                                    +
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </li>
        );
    };

    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charset="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                    <title>My Tree</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <div className="d-flex align-items-center ">
                    <div>
                        <img
                            src={logoo}
                            alt="Logo"
                            className="logo d-flex align-items-center w-35"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate('/Dashboard')}
                        />
                    </div>
                    <h2 className="menu-icon" onClick={toggleSidebar}>
                        <FiAlignJustify />
                    </h2>
                </div>
                <nav class="header-nav ms-auto">
                    <ul class="d-flex align-items-center">
                        <li class="nav-item dropdown pe-3">
                            <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                <div className="ic">
                                    <IoIosSettings />
                                </div>
                                <span class="d-none d-md-block dropdown-toggle ps-2">Settings</span>

                            </a>
                            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">


                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" to="/profile">
                                        <i className="bi bi-person"></i>
                                        <span>My Profile</span>
                                    </Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" onClick={() => logOut()} >
                                        <i className="bi bi-box-arrow-right"></i>
                                        <span>Sign Out</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </header>

              <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/dashboard">
                            <i className="bi bi-grid"></i>
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/tree">
                            <i className="bi bi-grid"></i>
                            <span>My Tree</span>
                        </Link>
                    </li>


                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/addagent">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Agent</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProductsAgent">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>All Products</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Events">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>All Events</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyProducts">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>MY Products</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Transfer">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Transfers</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDraw">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Withdraw</span>
                        </Link>
                    </li>
                    <li className="nav-heading">Pages</li>
                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Reports</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li>
                                <Link className="dropdown-item" to="/BalanceHistoryAgent">
                                    Balance History
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/AgentTransferHistory">
                                    Trasnfer History
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/MyWithdrawAgent">
                                    Withdraw History
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ContactUs">
                            <i className="bi bi-person"></i>
                            <span>Contact Us</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyWallet">
                            <i className="bi bi-person"></i>
                            <span>My Wallet</span>
                        </Link>
                    </li>

                </ul>
            </aside>

            <section>
                <div id="main" className="bg-light-2">
                    <div className="pagetitle">
                        <h1>My Tree</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="breadcrumb-item">Dashboard</li>
                                <li className="breadcrumb-item active">Tree</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="notice-container">

                        <div className="notice-icons">
                            <div className="icon">
                                <div className="circle green"></div>
                                <span>Stater</span>
                            </div>
                            <div className="icon">
                                <div className="circle bronze"></div>
                                <span>Bronze</span>
                            </div>
                            <div className="icon">
                                <div className="circle silver"></div>
                                <span>Silver</span>
                            </div>
                            <div className="icon">
                                <div className="circle gold"></div>
                                <span>Gold</span>
                            </div>
                            <div className="icon">
                                <div className="circle red"></div>
                                <span>Inactive</span>
                            </div>
                        </div>
                    </div>
                    <h4 className="text-center mt-3">The Agent Tree:</h4>
                    <div className="body genealogy-body genealogy-scroll">
                        <div
                            className="tab-pane fade active show"
                            id="all-booking"
                            role="tabpanel"
                        >
                            {isLoading ? (
                                <div className="'w-100 py-5 d-flex justify-content-center">
                                    <BallTriangle
                                        height={100}
                                        width={100}
                                        radius={5}
                                        color="#4fa94d"
                                        ariaLabel="ball-triangle-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    />
                                </div>
                            ) : (
                                <>
                                    <div className="genealogy-tree">
                                        <ul>
                                            {data?.data?.data && renderMember(data.data.data)} {/* Render the tree */}
                                        </ul>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            <Modal
                show={!!selectedMember}
                onHide={() => setSelectedMember(null)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Member Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedMember ? (
                        <div>
                            <p>
                                <strong>ID:</strong> {selectedMember._id}
                            </p>
                            <p>
                                <strong>Name:</strong> {selectedMember.firstName}{" "}
                                {selectedMember.secondName} {selectedMember.thirdName}{" "}
                                {selectedMember.lastName}
                            </p>
                            <p>
                                <strong>Email:</strong> {selectedMember.email}
                            </p>
                            <p>
                                <strong>PV:</strong> {selectedMember.pv}
                            </p>
                            <p>
                                <strong>Balance:</strong> {selectedMember.balance}
                            </p>
                        </div>
                    ) : (
                        <p>Loading...</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setSelectedMember(null)}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Tree;

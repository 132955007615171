import React from "react";
import { Navigate } from "react-router-dom";

export default function AdminProtectedRoute(props)
{
    if(localStorage.getItem('AdminToken')!==null)
    {
        return props.children
    }
    else
    {
        return <Navigate to={'/login'}/>
    }
}
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { AdminContext } from "../../Context/AdminContext"
import { jsPDF } from "jspdf";
import logoo from "../../imges/logo.png"
import { FiAlignJustify } from "react-icons/fi";
import { BallTriangle } from 'react-loader-spinner'

function AllRequestsProducts() {
    let { logOut } = useContext(AdminContext)
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768); // Sidebar open for desktop by default
    let navigate = useNavigate();
    const queryClient = useQueryClient();

    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768); // Open sidebar on desktop, close on mobile
        };

        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    // Fetch data for completed enrollments
    function getAllEnrollments() {
        return axios.get(`https://together-v.com/api/v1/enrollment/carts`, {
            withCredentials: true,
        });
    }

    let { data, isLoading } = useQuery("getAllEnrollments", getAllEnrollments);
    // Download PDF function
    const downloadPDF = () => {
        const doc = new jsPDF();
        let y = 10;

        // Title
        doc.setFontSize(16);
        doc.text("All Product Requests", 10, y);
        y += 10;

        // Iterate through each request and create a vertical structure
        data?.data.data.forEach((agent, index) => {
            const agentName = agent.agentId
                ? `${agent.agentId.firstName} ${agent.agentId.lastName}`
                : "N/A";
            const phone = agent.agentId?.phone || agent.phone || "N/A";

            // Add headers and data as rows
            doc.setFontSize(12);
            doc.text(`Request No. ${index + 1}`, 10, y);
            y += 10;
            doc.text("Name:", 10, y);
            doc.text(agentName, 50, y);
            y += 10;
            doc.text("Date:", 10, y);
            doc.text(agent.createdAt?.split("T")[0] || "N/A", 50, y);
            y += 10;
            doc.text("Product Name:", 10, y);
            doc.text(agent.productId.name || "N/A", 50, y);
            y += 10;
            doc.text("Phone:", 10, y);
            doc.text(phone, 50, y);
            y += 10;
            doc.text("Price:", 10, y);
            doc.text(String(agent.productId.price || "N/A"), 50, y);
            y += 10;
            doc.text("Apartment Number:", 10, y);
            doc.text(String(agent.apartmentNumber || "N/A"), 50, y);
            y += 10;
            doc.text("Building Number:", 10, y);
            doc.text(String(agent.buildingNumber || "N/A"), 50, y);
            y += 10;
            doc.text("City:", 10, y);
            doc.text(agent.city || "N/A", 50, y);
            y += 10;
            doc.text("Floor Number:", 10, y);
            doc.text(String(agent.floorNumber || "N/A"), 50, y);
            y += 10;
            doc.text("Governorate:", 10, y);
            doc.text(agent.governorate || "N/A", 50, y);
            y += 10;
            doc.text("Street:", 10, y);
            doc.text(agent.street || "N/A", 50, y);
            y += 20; // Add extra space before the next request

            // Check for page overflow
            if (y > 270) {
                doc.addPage();
                y = 10;
            }
        });

        // Save the PDF
        doc.save("ProductRequests.pdf");



    };

    const handleStatusChange = async (agentId, newStatus) => {
        if (!agentId) {
            toast.error("Invalid agent ID. Cannot update status.");
            return;
        }

        const token = localStorage.getItem("AdminToken");
        if (!token) {
            toast.error("No AdminToken found. Please log in.");
            navigate("/login");
            return;
        }

        try {
            const encodedStatus = encodeURIComponent(newStatus);
            const url = `https://together-v.com/api/v1/enrollment/shippingStatus/${agentId}?status=${encodedStatus}`;

            await axios.post(url, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                withCredentials: true,
            });

            toast.success(`Status successfully updated to "${newStatus}".`);
            queryClient.invalidateQueries(getAllEnrollments);

        } catch (error) {
            console.error("Error updating status:", error);
            const errorMessage = error.response?.data?.message || "Failed to update status.";
            toast.error(errorMessage);
        }
    };
    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                    <title>All Purchases</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <header id="header" className="header fixed-top d-flex align-items-center">
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <img
                                src={logoo}
                                alt="Logo"
                                className="logo d-flex align-items-center w-35"
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate('/DashboardAdmin')}
                            />
                        </div>
                        <h2 className="menu-icon" onClick={toggleSidebar}>
                            <FiAlignJustify />
                        </h2>
                    </div>
                </header>
            </header>

            <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/DashboardAdmin">
                            <i className="bi bi-grid"></i>
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Agents">
                            <i className="bi bi-grid"></i>
                            <span>All Agents</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEpin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Epin</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EPin">
                            <i className="bi bi-grid"></i>
                            <span>E-Pins</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EpinHistory">
                            <i className="bi bi-grid"></i>
                            <span>E-Pins History</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddProduct">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Product</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyTreeAdmin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>My Tree</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEvent">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Event</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProducts">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>All Products</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDrawRequest">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Withdraw Requests</span>
                        </Link>
                    </li>

                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Reports</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/MyTransfers">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>My Transfers</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllRequestsProducts">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Purchase</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/TransferRequests">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Transfers</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllWithDrawsAdmin">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Withdraws</span>
                                </Link>
                            </li>
                        </ul>
                    </li>


                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Filtration">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Filtration</span>
                        </Link>
                    </li>
                    <li className="nav-heading">Pages</li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllContactUs">
                            <i className="bi bi-person"></i>
                            <span>All Contact</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ProfileAdmin">
                            <i className="bi bi-person"></i>
                            <span>Profile</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" onClick={() => logOut()}>
                            <i className="bi bi-person"></i>
                            <span>Log Out</span>
                        </Link>
                    </li>
                </ul>
            </aside>



            <section>
                <div id="main" className="bg-light-2">
                    <div className="pagetitle">
                        <h1>All Purchases</h1>
                        <button className="btn btn-primary my-3" onClick={downloadPDF}>
                            Download as PDF
                        </button>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                                <li className="breadcrumb-item">Dashboard</li>
                                <li className="breadcrumb-item active">All Purchases</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="body genealogy-body genealogy-scroll">
                        <div
                            className="tab-pane fade active show"
                            id="all-booking"
                            role="tabpanel"
                        >
                            {isLoading ? <div className="'w-100 py-5 d-flex justify-content-center">
                                <BallTriangle
                                    height={100}
                                    width={100}
                                    radius={5}
                                    color="#4fa94d"
                                    ariaLabel="ball-triangle-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                            </div> : <>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Current Status</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Date</th>
                                                        <th scope="col">Product Name</th>
                                                        <th scope="col">Phone</th>
                                                        <th scope="col">Price</th>
                                                        <th scope="col">Apartment Number</th>
                                                        <th scope="col">Building Number</th>
                                                        <th scope="col">City</th>
                                                        <th scope="col">Floor Number</th>
                                                        <th scope="col">Governorate</th>
                                                        <th scope="col">Street</th>


                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data?.data.data.map((agent, index) => {
                                                        const agentName = agent.agentId
                                                            ? `${agent.agentId.firstName || "N/A"} ${agent.agentId.lastName || "N/A"}`
                                                            : "N/A";

                                                        return (
                                                            <tr key={agent._id || index}>
                                                                <td>{index + 1}</td>
                                                                <td>{agentName}</td>
                                                                <td>{agent.shippingStatus}</td>

                                                                
                                                                <td>
                                                                    <select
                                                                        onChange={(e) =>
                                                                            handleStatusChange(agent._id, e.target.value)
                                                                        }
                                                                        className="form-control w-900"
                                                                    >
                                                                        <option value="">Select a status</option>
                                                                        <option value="تم استلام الطلب">تم استلام الطلب</option>
                                                                        <option value="تم تأكيد الطلب">تم تأكيد الطلب</option>
                                                                        <option value="في انتظار شركة الشحن">في انتظار شركة الشحن</option>
                                                                        <option value="التوصيل قيد التقدم">التوصيل قيد التقدم</option>
                                                                        <option value="تم التوصيل">تم التوصيل</option>
                                                                        <option value="طلبات معلقة">طلبات معلقة</option>
                                                                        <option value="تم الغاء الطلب">تم الغاء الطلب</option>
                                                                    </select>
                                                                </td>
                                                                <td>{agent.createdAt?.split("T")[0] || "N/A"}</td>
                                                                <td>{agent.productId?.name || "N/A"}</td>
                                                                <td>{agent.agentId?.phone || agent.phone || "N/A"}</td>
                                                                <td>{agent.productId?.price || "N/A"}</td>
                                                                <td>{agent.apartmentNumber || "N/A"}</td>
                                                                <td>{agent.buildingNumber || "N/A"}</td>
                                                                <td>{agent.city || "N/A"}</td>
                                                                <td>{agent.floorNumber || "N/A"}</td>
                                                                <td>{agent.governorate || "N/A"}</td>
                                                                <td>{agent.street || "N/A"}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>


                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </>}


                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

export default AllRequestsProducts;

import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { AgentContext } from "../../Context/AgentContext"
import { useQuery } from "react-query";
import logoo from "../../imges/logo.png"
import { FiAlignJustify } from "react-icons/fi";
import { IoIosSettings } from "react-icons/io";

function Transfer() {
    const [agentId, setAgentId] = useState(""); // State for Agent ID
    const [amount, setAmount] = useState(""); // State for Amount
    const [walletPassword, setWalletPassword] = useState(""); // State for Wallet Password
    const [agentDetails, setAgentDetails] = useState(null); // State to store fetched agent details
    const { logOut } = useContext(AgentContext);
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768); // Sidebar open for desktop by default
    let navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768); // Open sidebar on desktop, close on mobile
        };

        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    const { data, refetch } = useQuery("getCurrentAgent", () =>
        axios.get(`https://together-v.com/api/v1/agent/getAgent`, {
            withCredentials: true,
        })
    );

    const handleAgentIdChange = async (e) => {
        const enteredId = e.target.value;
        setAgentId(enteredId);

        // Fetch agent details dynamically when agentId is updated
        if (enteredId) {
            try {
                const response = await axios.get(`https://together-v.com/api/v1/agent/getAgentByAdmin/${enteredId}`, {
                    withCredentials: true,
                });
                setAgentDetails(response.data.data); // Store fetched agent details
            } catch (error) {
                setAgentDetails(null); // Clear details if API fails
                console.error("Error fetching agent details:", error);
            }
        } else {
            setAgentDetails(null); // Clear details if input is empty
        }
    };

    async function handleTransfer(e) {
        e.preventDefault(); // Prevent default form submission

        try {
            const token = localStorage.getItem("AgentToken"); // Retrieve token from local storage

            const { data } = await axios.post(
                `https://together-v.com/api/v1/agent/transferBalance/${agentId}`, // Use the agentId in the API URL
                {
                    request: amount,
                    walletPassword,
                },
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${token}`, // Include Authorization header
                    },
                }
            );

            if (data.message === "Balance transfer successfully") {
                toast.success("Balance transferred successfully");
                setAgentId(""); // Clear the Agent ID field
                setAmount(""); // Clear the Amount field
                setWalletPassword(""); // Clear the Wallet Password field
                setAgentDetails(null); // Clear agent details
                refetch(); // Reload the data
            }
        } catch (err) {
            if (err.response && err.response.status === 401) {
                toast.error("Unauthorized. Please log in again.");
            } else {
                toast.error(err.response.data.message || "Failed to transfer balance.");
            }
        }
    }
    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                    <title>Transfers</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <div className="d-flex align-items-center ">
                   <div>
                        <img
                            src={logoo}
                            alt="Logo"
                            className="logo d-flex align-items-center w-35"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate('/Dashboard')}
                        />
                    </div>
                    <h2 className="menu-icon" onClick={toggleSidebar}>
                        <FiAlignJustify />
                    </h2>                </div>

               <nav class="header-nav ms-auto">
                    <ul class="d-flex align-items-center">
                        <li class="nav-item dropdown pe-3">
                            <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                <div className="ic">
                                    <IoIosSettings />
                                </div>
                                <span class="d-none d-md-block dropdown-toggle ps-2">Settings</span>

                            </a>
                            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">


                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" to="/profile">
                                        <i className="bi bi-person"></i>
                                        <span>My Profile</span>
                                    </Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" onClick={() => logOut()} >
                                        <i className="bi bi-box-arrow-right"></i>
                                        <span>Sign Out</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </header>

            <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/dashboard">
                            <i className="bi bi-grid"></i>
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/tree">
                            <i className="bi bi-grid"></i>
                            <span>My Tree</span>
                        </Link>
                    </li>


                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/addagent">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Agent</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProductsAgent">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>All Products</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Events">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>All Events</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyProducts">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>MY Products</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Transfer">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Transfers</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDraw">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Withdraw</span>
                        </Link>
                    </li>
                    <li className="nav-heading">Pages</li>
                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Reports</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li>
                                <Link className="dropdown-item" to="/BalanceHistoryAgent">
                                    Balance History
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/AgentTransferHistory">
                                    Trasnfer History
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/MyWithdrawAgent">
                                    Withdraw History
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ContactUs">
                            <i className="bi bi-person"></i>
                            <span>Contact Us</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyWallet">
                            <i className="bi bi-person"></i>
                            <span>My Wallet</span>
                        </Link>
                    </li>

                </ul>
            </aside>

            <section>
                <div id="main" className="bg-light-2">
                    <div className="pagetitle">
                        <h1>Transfer Money</h1>
                    </div>
                    <div className="body genealogy-body genealogy-scroll">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">

                                    <div className="col-12 bg-light text-start mb-4">
                                        <label className="text-16 text-light-1 ">Balance</label>

                                        <div className="form-control">
                                            <input
                                                type="text"
                                                value={data?.data.data.balance}
                                                disabled
                                            />
                                        </div>
                                        {agentDetails && (
                                            <p className="text-success mt-1">
                                                Agent Name: {agentDetails.firstName} {agentDetails.secondName} {agentDetails.thirdName} {agentDetails.lastName}
                                            </p>
                                        )}
                                    </div>
                                    <form onSubmit={handleTransfer}>
                                        <div className="col-12">
                                            <div className="form-input">
                                                <input
                                                    type="text"
                                                    value={agentId}
                                                    onChange={handleAgentIdChange}
                                                    required
                                                />
                                                <label className="lh-1 text-16 text-light-1">Agent ID</label>
                                            </div>
                                            {agentDetails && (
                                                <p className="text-success mt-1">
                                                    Agent Name: {agentDetails.firstName} {agentDetails.secondName} {agentDetails.thirdName} {agentDetails.lastName}
                                                </p>
                                            )}
                                        </div>
                                        <br />
                                        <div className="col-12">
                                            <div className="form-input">
                                                <input
                                                    type="number"
                                                    value={amount}
                                                    onChange={(e) => setAmount(e.target.value)}
                                                    required
                                                />
                                                <label className="lh-1 text-16 text-light-1">Amount</label>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="col-12">
                                            <div className="form-input">
                                                <input
                                                    type="text"
                                                    value={walletPassword}
                                                    onChange={(e) => setWalletPassword(e.target.value)}
                                                    required
                                                />
                                                <label className="lh-1 text-16 text-light-1">Wallet Password</label>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="col-auto">
                                            <button className="button md blue-1 bg-blue-1-05 text-blue-1 mt-2" type="submit">
                                                Transfer
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Transfer;

import React from "react";
import { Navigate } from "react-router-dom";

export default function AgentProtectedRoute(props)
{
    if(localStorage.getItem('AgentToken')!==null)
    {
        return props.children
    }
    else
    {
        return <Navigate to={'/login'}/>
    }
}
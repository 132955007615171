import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { AdminContext } from "../../Context/AdminContext"
import logoo from "../../imges/logo.png"
import toast from "react-hot-toast";
import { FiAlignJustify } from "react-icons/fi";


function DashboardAdmin() {
    let { logOut } = useContext(AdminContext)
    const [directCommission, setDirectCommission] = useState(50); // Default value
    const [indirectCommission, setIndirectCommission] = useState(1500); // Default value
    const [directEditable, setDirectEditable] = useState(false);
    const [indirectEditable, setIndirectEditable] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768); // Sidebar open for desktop by default
    const [commissionStatus, setCommissionStatus] = useState("");
    const [agentId, setAgentId] = useState("");
    let navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768); // Open sidebar on desktop, close on mobile
        };

        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    const updateCommission = useMutation(
        async () => {
            const response = await axios.post(
                "https://together-v.com/api/v1/agent/updateCommission",
                {
                    directCommission,
                    indirectCommission,
                },
                {
                    withCredentials: true,
                }
            );
            return response.data;
        },
        {
            onSuccess: () => {
                toast.success("Commissions updated successfully!");
                setDirectEditable(false);
                setIndirectEditable(false);
            },
            onError: (error) => {
                toast.error(
                    error.response?.data?.message || "Failed to update commissions"
                );
            },
        }
    );

    const handleSubmit = () => {
        updateCommission.mutate();
    };

    function getAllAgents() {
        return axios.get(`https://together-v.com/api/v1/agent/allAgents`, {
            withCredentials: true
        });
    }

    let { data } = useQuery('getAllAgents', getAllAgents);


    function getAllPendings() {
        return axios.get(`https://together-v.com/api/v1/withdraw/allPendingRequests`, {
            withCredentials: true
        });
    }
    let response = useQuery('getAllPendings', getAllPendings);

    function getAllCompletes() {
        return axios.get(`https://together-v.com/api/v1/withdraw/allCompletedRequests`, {
            withCredentials: true
        });
    }
    let allCompletes = useQuery('getAllCompletes', getAllCompletes);

    function getAllProfitable() {
        return axios.get(`https://together-v.com/api/v1/agent/moreProfitable`, {
            withCredentials: true
        });
    }
    let allProfitable = useQuery('getAllProfitable', getAllProfitable);
   
    function allProductss() {
        const limit = 1000000;
        const page = 1;

        return axios.get(`https://together-v.com/api/v1/product/allProducts`, {
            params: {
                limit,
                page,
            },
            withCredentials: true
        });
    }

    let AllProductss = useQuery('allProductss', allProductss);

    function getAllPurchase() {
        return axios.get(`https://together-v.com/api/v1/agent/AgentsTransferHistory`, {
            withCredentials: true
        });
    }
    let GetAllPurchase = useQuery('getAllPurchase', getAllPurchase);

    const handleTopLevelCommissionChange = async () => {
        const validValues = ["true", "false"];
        const trimmedValue = commissionStatus.trim().toLowerCase();

        if (!validValues.includes(trimmedValue)) {
            toast.error("Please enter a valid value: 'true' or 'false'.");
            return;
        }

        try {
            // Add commission as a query parameter in the URL
            const response = await axios.post(
                `https://together-v.com/api/v1/agent/changeCommissionStatus/{agentId}?commission=${trimmedValue}`,
                {}, // Empty body, since data is in query parameters
                { withCredentials: true }
            );

            if (response.data?.message === "Commission status changed successfully") {
                toast.success(`Commission status updated: ${response.data.data.status}`);
            } else {
                toast.error("Unexpected response from the server. Please try again.");
            }
        } catch (error) {
            console.error("Error updating commission status:", error);
            toast.error("Failed to update commission status. Please try again.");
        }
    };


    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charset="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                    <title>Dashboard</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <header id="header" className="header fixed-top d-flex align-items-center">
                    <div className="d-flex align-items-center ">
                        <div>
                            <img
                                src={logoo}
                                alt="Logo"
                                className="logo d-flex align-items-center w-35"
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate('/DashboardAdmin')}
                            />
                        </div>

                        <h2 className="menu-icon" onClick={toggleSidebar}>
                            <FiAlignJustify />
                        </h2>
                    </div>
                </header>
            </header>


            <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/DashboardAdmin">
                            <i className="bi bi-grid"></i>
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Agents">
                            <i className="bi bi-grid"></i>
                            <span>All Agents</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEpin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Epin</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EPin">
                            <i className="bi bi-grid"></i>
                            <span>E-Pins</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EpinHistory">
                            <i className="bi bi-grid"></i>
                            <span>E-Pins History</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddProduct">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Product</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyTreeAdmin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>My Tree</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEvent">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Event</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProducts">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>All Products</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDrawRequest">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Withdraw Requests</span>
                        </Link>
                    </li>

                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Reports</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/MyTransfers">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>My Transfers</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllRequestsProducts">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Purchase</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/TransferRequests">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Transfers</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllWithDrawsAdmin">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Withdraws</span>
                                </Link>
                            </li>
                        </ul>
                    </li>


                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Filtration">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Filtration</span>
                        </Link>
                    </li>
                    <li className="nav-heading">Pages</li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllContactUs">
                            <i className="bi bi-person"></i>
                            <span>All Contact</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ProfileAdmin">
                            <i className="bi bi-person"></i>
                            <span>Profile</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" onClick={() => logOut()}>
                            <i className="bi bi-person"></i>
                            <span>Log Out</span>
                        </Link>
                    </li>
                </ul>
            </aside>



            <div id="main" className="bg-light-2">
                <div className="pagetitle">
                    <h1>Dashboard</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item active">Dashboard</li>
                        </ol>
                    </nav>
                </div>
                <div>
                    <div className="container mt-4">

                        <div className="row">
                            <div className="row mb-4">
                                <div className="col-lg-6">
                                    <label htmlFor="commissionStatus">Commission Status</label>
                                    <select
                                        id="commissionStatus"
                                        className="form-control"
                                        value={commissionStatus}
                                        onChange={(e) => setCommissionStatus(e.target.value)}
                                    >
                                        <option value="">Select a status</option>
                                        <option value="true">True</option>
                                        <option value="false">False</option>
                                    </select>
                                </div>
                                <div className="col-lg-12 text-center mt-3">
                                    <button
                                        className="btn btn-primary"
                                        onClick={handleTopLevelCommissionChange}
                                    >
                                        Update Commission Status
                                    </button>
                                </div>
                            </div>





                            <div className="d-flex">
                                {/* Direct Commission */}
                                <div className="col-lg-6 me-3">
                                    <label htmlFor="directCommission">Direct Commission</label>
                                    <input
                                        type="text"
                                        id="directCommission"
                                        className="form-control"
                                        value={directCommission}
                                        disabled={!directEditable}
                                        onChange={(e) => setDirectCommission(e.target.value)}
                                    />
                                    <button
                                        className="btn btn-secondary mt-2"
                                        onClick={() => setDirectEditable(true)}
                                    >
                                        Edit
                                    </button>
                                </div>

                                {/* Indirect Commission */}
                                <div className="col-lg-6">
                                    <label htmlFor="indirectCommission">Indirect Commission</label>
                                    <input
                                        type="text"
                                        id="indirectCommission"
                                        className="form-control"
                                        value={indirectCommission}
                                        disabled={!indirectEditable}
                                        onChange={(e) => setIndirectCommission(e.target.value)}
                                    />
                                    <button
                                        className="btn btn-secondary mt-2"
                                        onClick={() => setIndirectEditable(true)}
                                    >
                                        Edit
                                    </button>
                                </div>
                            </div>

                            {/* Submit Button */}
                            <div className="text-center my-4">
                                <button
                                    className="btn btn-primary"
                                    onClick={handleSubmit}
                                    disabled={updateCommission.isLoading}
                                >
                                    Submit
                                </button>
                            </div>
                            <div className="col-md-4">
                                <div className="card text-center">
                                    <div className="card-body" id="agentsNum">
                                        <h3>{data?.data.data.length}</h3>
                                        <h5 className="card-title">All Agents</h5>
                                        <Link to="/Agents" className="btn btn-primary">View Details</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card text-center">
                                    <div className="card-body">
                                        <h3>{AllProductss?.data?.data.data.length}</h3>
                                        <h5 className="card-title">All Products</h5>
                                        <Link to="/AllProducts" className="btn btn-primary">View Details</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 ">
                                <div className="card text-center">
                                    <div className="card-body">
                                        <h3>{response?.data?.data.data.length}</h3>
                                        <h5 className="card-title">Pending Withdrawls Requests</h5>
                                        <Link to="/WithDrawRequest" className="btn btn-primary">View Details</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-3">
                                <div className="card text-center">
                                    <div className="card-body">
                                        <h3>{allCompletes?.data?.data.data.length}</h3>
                                        <h5 className="card-title">Completed Withdrawls Requests</h5>
                                        <Link to="/ViewCompletedRequests" className="btn btn-primary">View Details</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-3">
                                <div className="card text-center">
                                    <div className="card-body">
                                        <h3>{GetAllPurchase?.data?.data.data.length}</h3>
                                        <h5 className="card-title">Completed Purchases Requests</h5>
                                        <Link to="/ViewPurcahsesRequests" className="btn btn-primary">View Details</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h2 className="text-center mt-4">More Profitable</h2>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Balance</th>

                                </tr>
                            </thead>
                            <tbody>
                                {allProfitable?.data?.data.data.map((agent, index) => (
                                    <tr key={agent._id}>
                                        <td>{index + 1}</td>
                                        <td>
                                            {agent.firstName} {agent.lastName}
                                        </td>
                                        <td>
                                            {agent.balance}
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>

                       
                        

                        
                    </div>

                </div>

            </div>




        </>
    );
}

export default DashboardAdmin;

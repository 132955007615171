import React, {  useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import shape01 from "../../imges/breadcrumb_shape01.svg";
import shape02 from "../../imges/breadcrumb_shape02.svg";
import shape03 from "../../imges/breadcrumb_shape03.svg";
import shape04 from "../../imges/breadcrumb_shape04.svg";
import { Link, useNavigate } from "react-router-dom";
import { AgentContext } from "../../Context/AgentContext";
import { AdminContext } from "../../Context/AdminContext";
import logoo from "../../imges/logo13.png"

function Login() {
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { setAgentToken,setAgentRefreshToken } = useContext(AgentContext);
    const { setAdminToken,setAdminRefreshToken } = useContext(AdminContext);

    async function submitLogin(values) {
        try {
            const { data } = await axios.post(`https://together-v.com/api/v1/agent/login`, values, {
                withCredentials: true,
            });

            if (data.message === "Login and authentication successful") {
                if (data.data.user.role === "agent") {
                    if (!data.data.user.isVerified) {
                        navigate("/VerifyAgent");
                    } else {
                        localStorage.setItem("AgentToken", data.data.accessToken);
                        localStorage.setItem("AgentRefreshToken", data.data.accessToken);

                        setAgentToken(data.data.accessToken);
                        setAgentRefreshToken(data.data.refreshToken)
                        navigate("/Dashboard");
                    }
                } else if (data.data.user.role === "admin") {
                    localStorage.setItem("AdminToken", data.data.accessToken);
                    localStorage.setItem("AdminRefreshToken", data.data.accessToken);

                    setAdminToken(data.data.accessToken);
                    setAdminRefreshToken(data.data.refreshToken)
                    navigate("/DashboardAdmin");
                }
            }
        } catch (error) {
            setError("Invalid email or password");
        }
    }

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Email is required"),
            password: Yup.string().required("Password is required"),
        }),
        onSubmit: submitLogin,
    });


    return (
        <>
            <header>
                <Helmet>
                    <meta charset="UTF-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css" rel="stylesheet" />
                    <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" rel="stylesheet" />
                    <link href="https://cdnjs.cloudflare.com/ajax/libs/mdb-ui-kit/7.3.0/mdb.min.css" rel="stylesheet" />
                    <link rel="stylesheet" href="./css/main.css" />
                    <title>Log In</title>
                </Helmet>
            </header>

             <main>
                <section className="con1 py-70">
                    <div className="container">
                        <img className="img1" src={shape01} alt="shape" />
                        <img className="img2" src={shape02} alt="shape" />
                        <img className="img3" src={shape03} alt="shape" />
                        <img className="img4" src={shape04} alt="shape" />
                        <div className="text">
                            <h1>Login</h1>
                            <p>
                                <a href="#">Home</a>{" "}
                                <i className="fa-solid fa-chevron-right"></i>{" "}
                                <a href="login.html">Login</a>
                            </p>
                        </div>
                    </div>
                </section>

                <section className="log1 py-70">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12 form">
                                {error && <div className="alert alert-danger">{error}</div>}
                                <h3>Welcome back!</h3>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="mb-4 form1">
                                        <label htmlFor="email" className="form-label">
                                            Email *
                                        </label>
                                        <input
                                            type="text"
                                            id="email"
                                            className={`form-control ${formik.touched.email && formik.errors.email ? "is-invalid" : ""}`}
                                            placeholder="Email"
                                            {...formik.getFieldProps("email")}
                                        />
                                        {formik.touched.email && formik.errors.email && (
                                            <div className="invalid-feedback">{formik.errors.email}</div>
                                        )}
                                    </div>
                                    <div className="mb-4 form1">
                                        <label htmlFor="password" className="form-label">
                                            Password *
                                        </label>
                                        <input
                                            type="password"
                                            id="password"
                                            className={`form-control ${formik.touched.password && formik.errors.password ? "is-invalid" : ""}`}
                                            placeholder="Password"
                                            {...formik.getFieldProps("password")}
                                        />
                                        {formik.touched.password && formik.errors.password && (
                                            <div className="invalid-feedback">{formik.errors.password}</div>
                                        )}
                                    </div>
                                    <div className="row mb-4">
                                    <div className="d-flex justify-content-center firstColor">
                                        <Link to="/ForgetPassword">Forgot password?</Link>
                                    </div>
                                </div>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        disabled={formik.isSubmitting}
                                    >
                                        {formik.isSubmitting ? (
                                            <>
                                                <span className="spinner-border spinner-border-sm me-2"></span>
                                                Logging in...
                                            </>
                                        ) : (
                                            <>
                                                Log in <i className="fa-solid fa-arrow-right"></i>
                                            </>
                                        )}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

       
        </>
    );
}

export default Login;

import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { AdminContext } from "../../Context/AdminContext"
import logoo from "../../imges/logo.png"
import { FiAlignJustify } from "react-icons/fi";
import { useFormik } from "formik";
import * as Yup from "yup";

function ProfileAdmin() {
    let { logOut } = useContext(AdminContext)
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768); // Sidebar open for desktop by default
    const [isLoading, setIsLoading] = useState(false); // Add loading state
    let navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768); // Open sidebar on desktop, close on mobile
        };

        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    function getAdmin() {
        return axios.get(`https://together-v.com/api/v1/agent/getAgent`, {
            withCredentials: true
        });
    }

    let { data } = useQuery('getAdmin', getAdmin);
    const handlePasswordChange = async (values) => {
        setIsLoading(true); // Set loading to true when submitting

        try {
            const response = await axios.post(
                `https://together-v.com/api/v1/agent/updatePasswordByAgent`,
                {
                    oldPass: values.currentPassword,
                    newPass: values.newPassword,
                },
                { withCredentials: true }
            );

            if (response.data.message === "Password updated successfully") {
                toast.success("Password updated successfully!");
            } else {
                toast.error("Failed to update password");
            }
        } catch (error) {
            toast.error(error.response?.data?.message || "An error occurred");
        }
        finally {
            setIsLoading(false); // Set loading to false after completion
        }
    };

    const passwordFormik = useFormik({
        initialValues: {
            currentPassword: "",
            newPassword: "",
            reenterPassword: "",
        },
        validationSchema: Yup.object({
            currentPassword: Yup.string()
                .required("Current password is required"),
            newPassword: Yup.string()
                .required("New password is required")
                .min(6, "Password must be at least 6 characters long"),
            reenterPassword: Yup.string()
                .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
                .required("Please re-enter your new password"),
        }),
        onSubmit: handlePasswordChange,
    });

    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charset="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />

                    <title>Profile</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <header id="header" className="header fixed-top d-flex align-items-center">
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <img
                                src={logoo}
                                alt="Logo"
                                className="logo d-flex align-items-center w-35"
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate('/DashboardAdmin')}
                            />
                        </div>
                        <h2 className="menu-icon" onClick={toggleSidebar}>
                            <FiAlignJustify />
                        </h2>
                    </div>
                </header>
            </header>


            <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/DashboardAdmin">
                            <i className="bi bi-grid"></i>
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Agents">
                            <i className="bi bi-grid"></i>
                            <span>All Agents</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEpin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Epin</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EPin">
                            <i className="bi bi-grid"></i>
                            <span>E-Pins</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EpinHistory">
                            <i className="bi bi-grid"></i>
                            <span>E-Pins History</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddProduct">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Product</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyTreeAdmin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>My Tree</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEvent">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Event</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProducts">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>All Products</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDrawRequest">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Withdraw Requests</span>
                        </Link>
                    </li>

                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Reports</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/MyTransfers">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>My Transfers</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllRequestsProducts">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Purchase</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/TransferRequests">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Transfers</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllWithDrawsAdmin">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Withdraws</span>
                                </Link>
                            </li>
                        </ul>
                    </li>


                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Filtration">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Filtration</span>
                        </Link>
                    </li>
                    <li className="nav-heading">Pages</li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllContactUs">
                            <i className="bi bi-person"></i>
                            <span>All Contact</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ProfileAdmin">
                            <i className="bi bi-person"></i>
                            <span>Profile</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" onClick={() => logOut()}>
                            <i className="bi bi-person"></i>
                            <span>Log Out</span>
                        </Link>
                    </li>
                </ul>
            </aside>

            <main id="main" className="main bg-light-2">
                <div className="pagetitle">
                    <h1>Profile</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item">Users</li>
                            <li className="breadcrumb-item active">Profile</li>
                        </ol>
                    </nav>
                </div>

                <section className="section profile">
                    <div className="row justify-content-between">

                        <div className="col-xl-8">
                            <div className="card border-0">
                                <div className="card-body p-3">
                                    <ul className="nav nav-tabs border-bottom" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className="nav-link active"
                                                id="overview-tap"
                                                data-bs-toggle="pill"
                                                data-bs-target="#profile-overview"
                                                type="button"
                                                role="tab"
                                                aria-controls="profile-overview"
                                                aria-selected="true"
                                            >
                                                Overview
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className="nav-link"
                                                id="edit-tap"
                                                data-bs-toggle="pill"
                                                data-bs-target="#profile-edit"
                                                type="button"
                                                role="tab"
                                                aria-controls="profile-edit"
                                                aria-selected="false"
                                                tabIndex="-1"
                                            >
                                                Edit Profile
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className="nav-link"
                                                id="change-password"
                                                data-bs-toggle="pill"
                                                data-bs-target="#profile-change-password"
                                                type="button"
                                                role="tab"
                                                aria-controls="profile-change-password"
                                                aria-selected="false"
                                                tabIndex="-1"
                                            >
                                                Change Password
                                            </button>
                                        </li>
                                    </ul>
                                    <div className="tab-content pt-2">
                                        <div
                                            className="tab-pane fade profile-overview show active"
                                            id="profile-overview"
                                            role="tabpanel"
                                            aria-labelledby="#overview-tap"
                                        >
                                            <h5 className="card-title py-3 mb-2">Profile Details</h5>
                                            <div className="row mb-3">
                                                <div className="col-lg-3 col-md-4 label">Full Name</div>
                                                <div className="col-lg-9 col-md-8">{data?.data.data.firstName} {data?.data.data.secondName} {data?.data.data.thirdName} {data?.data.data.lastName}</div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-lg-3 col-md-4 label">Country</div>
                                                <div className="col-lg-9 col-md-8">{data?.data.data.country}</div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-lg-3 col-md-4 label">Phone</div>
                                                <div className="col-lg-9 col-md-8">{data?.data.data.phone}</div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-lg-3 col-md-4 label">Email</div>
                                                <div className="col-lg-9 col-md-8">{data?.data.data.email}</div>
                                            </div>
                                        </div>


                                        <div className="tab-pane fade profile-edit pt-3" id="profile-edit" role="tabpanel" aria-labelledby="#edit-tap">
                                            <form
                                                onSubmit={async (e) => {
                                                    e.preventDefault();
                                                    const updatedData = {
                                                        firstName: e.target.firstName.value,
                                                        secondName: e.target.secondName.value,
                                                        thirdName: e.target.thirdName.value,
                                                        lastName: e.target.lastName.value,
                                                        email: e.target.email.value,
                                                        phone: e.target.phone.value,
                                                        country: e.target.country.value,
                                                    };

                                                    try {
                                                        const token = localStorage.getItem("AgentToken"); // Assuming the token is stored in localStorage

                                                        const { data } = await axios.post(
                                                            `https://together-v.com/api/v1/agent/updateAgent`,
                                                            updatedData,
                                                            {
                                                                withCredentials: true,
                                                                headers: {
                                                                    Authorization: `Bearer ${token}`,
                                                                },
                                                            }
                                                        );

                                                        if (data.message === "Agent updated successfully") {
                                                            toast.success("Profile updated successfully");
                                                        }
                                                    } catch (err) {
                                                        toast.error(err);
                                                    }
                                                }}
                                            >
                                                <div className="row mb-3">
                                                    <label htmlFor="firstName" className="col-md-4 col-lg-3 col-form-label">First Name</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="firstName"
                                                            type="text"
                                                            className="form-control"
                                                            id="firstName"
                                                            defaultValue={data?.data.data.firstName || ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label htmlFor="secondName" className="col-md-4 col-lg-3 col-form-label">Second Name</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="secondName"
                                                            type="text"
                                                            className="form-control"
                                                            id="secondName"
                                                            defaultValue={data?.data.data.secondName || ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label htmlFor="thirdName" className="col-md-4 col-lg-3 col-form-label">Third Name</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="thirdName"
                                                            type="text"
                                                            className="form-control"
                                                            id="thirdName"
                                                            defaultValue={data?.data.data.thirdName || ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label htmlFor="lastName" className="col-md-4 col-lg-3 col-form-label">Last Name</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="lastName"
                                                            type="text"
                                                            className="form-control"
                                                            id="lastName"
                                                            defaultValue={data?.data.data.lastName || ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label htmlFor="country" className="col-md-4 col-lg-3 col-form-label">Country</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="country"
                                                            type="text"
                                                            className="form-control"
                                                            id="country"
                                                            defaultValue={data?.data.data.country || ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label htmlFor="phone" className="col-md-4 col-lg-3 col-form-label">Phone</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="phone"
                                                            type="text"
                                                            className="form-control"
                                                            id="phone"
                                                            defaultValue={data?.data.data.phone || ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label htmlFor="email" className="col-md-4 col-lg-3 col-form-label">Email</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="email"
                                                            type="email"
                                                            className="form-control"
                                                            id="email"
                                                            defaultValue={data?.data.data.email || ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="text-center">
                                                    <button type="submit" className="button dark-1 bg-blue-1 md text-white">Save Changes</button>
                                                </div>
                                            </form>
                                        </div>

                                        <div
                                            className="tab-pane fade profile-change-password pt-3"
                                            id="profile-change-password"
                                            role="tabpanel"
                                            aria-labelledby="#change-password"
                                        >
                                            <form onSubmit={passwordFormik.handleSubmit}>
                                                <div className="row mb-3">
                                                    <label htmlFor="currentPassword" className="col-md-4 col-lg-3 col-form-label">
                                                        Current Password
                                                    </label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="currentPassword"
                                                            type="password"
                                                            className="form-control"
                                                            id="currentPassword"
                                                            value={passwordFormik.values.currentPassword}
                                                            onChange={passwordFormik.handleChange}
                                                            onBlur={passwordFormik.handleBlur}
                                                        />
                                                        {passwordFormik.touched.currentPassword && passwordFormik.errors.currentPassword && (
                                                            <div className="text-danger">{passwordFormik.errors.currentPassword}</div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label htmlFor="newPassword" className="col-md-4 col-lg-3 col-form-label">
                                                        New Password
                                                    </label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="newPassword"
                                                            type="password"
                                                            className="form-control"
                                                            id="newPassword"
                                                            value={passwordFormik.values.newPassword}
                                                            onChange={passwordFormik.handleChange}
                                                            onBlur={passwordFormik.handleBlur}
                                                        />
                                                        {passwordFormik.touched.newPassword && passwordFormik.errors.newPassword && (
                                                            <div className="text-danger">{passwordFormik.errors.newPassword}</div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label htmlFor="reenterPassword" className="col-md-4 col-lg-3 col-form-label">
                                                        Re-enter New Password
                                                    </label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="reenterPassword"
                                                            type="password"
                                                            className="form-control"
                                                            id="reenterPassword"
                                                            value={passwordFormik.values.reenterPassword}
                                                            onChange={passwordFormik.handleChange}
                                                            onBlur={passwordFormik.handleBlur}
                                                        />
                                                        {passwordFormik.touched.reenterPassword && passwordFormik.errors.reenterPassword && (
                                                            <div className="text-danger">{passwordFormik.errors.reenterPassword}</div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="text-center">
                                                    <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                                        {isLoading ? (
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        ) : (
                                                            "Change Password"
                                                        )}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>


        </>
    );
}

export default ProfileAdmin;

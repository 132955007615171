import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import { AdminContext } from "../../Context/AdminContext"
import logoo from "../../imges/logo.png"
import { FiAlignJustify } from "react-icons/fi";

function UpdateProductData() {
    let params = useParams();
    let { logOut } = useContext(AdminContext)
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768); // Sidebar open for desktop by default
    let navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768); // Open sidebar on desktop, close on mobile
        };

        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    const [productData, setProductData] = useState({
        name: '',
        price: '',
        description: '',
        category: '',
        quantity: '',
        pv: ''

    });

    // Fetch product data
    function getProduct() {
        return axios.get(`https://together-v.com/api/v1/product/getProduct/${params.id}`, {
            withCredentials: true,
        });
    }

    const { data } = useQuery("getProduct", getProduct, {
        onSuccess: (data) => {
            setProductData({
                name: data?.data?.data?.name || "",
                price: data?.data?.data?.price || "",
                description: data?.data?.data?.description || "",
                category: data?.data?.data?.category || "",
                quantity: data?.data?.data?.quantity || "",
                pv: data?.data?.data?.pv || "",

            });
        },
    });

    // Mutation to update product
    const updateProductMutation = useMutation(
        (updatedData) => axios.patch(`https://together-v.com/api/v1/product/${params.id}`, updatedData, {
            withCredentials: true,
        }),
        {
            onSuccess: () => {
                toast.success("Product updated successfully!");
            },
            onError: () => {
                toast.error("Error updating product.");
            }
        }
    );

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        // Destructure productData state
        const { name, price, description, category, quantity, pv } = productData;

        // Ensure price and pv are strings
        const updatedData = {
            name,
            price: String(price), // Convert price to string
            description,
            category,
            quantity: String(quantity),
            pv: String(pv), // Convert pv to string
        };

        // Send the data to the server
        updateProductMutation.mutate(updatedData);
    };



    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProductData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };
    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                    <title>Update Product</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <header id="header" class="header fixed-top d-flex align-items-center">
                    <div class="d-flex align-items-center justify-content-between">
                        <div>
                            <img
                                src={logoo}
                                alt="Logo"
                                className="logo d-flex align-items-center w-35"
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate('/DashboardAdmin')}
                            />
                        </div>
                        <h2 className="menu-icon" onClick={toggleSidebar}>
                            <FiAlignJustify />
                        </h2>
                    </div>
                </header>
            </header>

            <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/DashboardAdmin">
                            <i className="bi bi-grid"></i>
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Agents">
                            <i className="bi bi-grid"></i>
                            <span>All Agents</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEpin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Epin</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EPin">
                            <i className="bi bi-grid"></i>
                            <span>E-Pins</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EpinHistory">
                            <i className="bi bi-grid"></i>
                            <span>E-Pins History</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddProduct">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Product</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyTreeAdmin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>My Tree</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEvent">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Event</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProducts">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>All Products</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDrawRequest">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Withdraw Requests</span>
                        </Link>
                    </li>

                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Reports</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/MyTransfers">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>My Transfers</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllRequestsProducts">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Purchase</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/TransferRequests">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Transfers</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllWithDrawsAdmin">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Withdraws</span>
                                </Link>
                            </li>
                        </ul>
                    </li>


                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Filtration">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Filtration</span>
                        </Link>
                    </li>
                    <li className="nav-heading">Pages</li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllContactUs">
                            <i className="bi bi-person"></i>
                            <span>All Contact</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ProfileAdmin">
                            <i className="bi bi-person"></i>
                            <span>Profile</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" onClick={() => logOut()}>
                            <i className="bi bi-person"></i>
                            <span>Log Out</span>
                        </Link>
                    </li>
                </ul>
            </aside>



            <section>
                <div id="main" className="bg-light-2">
                    <div className="pagetitle">
                        <h1>Update Product</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="index.html">Home</a>
                                </li>
                                <li className="breadcrumb-item">Dashboard</li>
                                <li className="breadcrumb-item active">Update Product </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="body genealogy-body genealogy-scroll">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="mb-3">Update Product </h2>
                                    <form onSubmit={handleSubmit}>
                                        <div className="row mb-3">
                                            <label htmlFor="name" className="col-md-4 col-lg-3 col-form-label">Name</label>
                                            <div className="col-md-8 col-lg-9">
                                                <input
                                                    name="name"
                                                    type="text"
                                                    className="form-control"
                                                    id="name"
                                                    value={productData.name}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <label htmlFor="price" className="col-md-4 col-lg-3 col-form-label">Price</label>
                                            <div className="col-md-8 col-lg-9">
                                                <input
                                                    name="price"
                                                    type="text"
                                                    className="form-control"
                                                    id="price"
                                                    value={productData.price}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <label htmlFor="description" className="col-md-4 col-lg-3 col-form-label">Description</label>
                                            <div className="col-md-8 col-lg-9">
                                                <input
                                                    name="description"
                                                    type="text"
                                                    className="form-control"
                                                    id="description"
                                                    value={productData.description}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <label htmlFor="category" className="col-md-4 col-lg-3 col-form-label">Category</label>
                                            <div className="col-md-8 col-lg-9">
                                                <input
                                                    name="category"
                                                    type="text"
                                                    className="form-control"
                                                    id="category"
                                                    value={productData.category}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="quantity" className="col-md-4 col-lg-3 col-form-label">Quantity</label>
                                            <div className="col-md-8 col-lg-9">
                                                <input
                                                    name="quantity"
                                                    type="text"
                                                    className="form-control"
                                                    id="quantity"
                                                    value={productData.quantity}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div><div className="row mb-3">
                                            <label htmlFor="pv" className="col-md-4 col-lg-3 col-form-label">Pv</label>
                                            <div className="col-md-8 col-lg-9">
                                                <input
                                                    name="pv"
                                                    type="text"
                                                    className="form-control"
                                                    id="pv"
                                                    value={productData.pv}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" className="button dark-1 bg-blue-1 md text-white" disabled={updateProductMutation.isLoading}>
                                                {updateProductMutation.isLoading ? 'Saving...' : 'Save Changes'}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default UpdateProductData;

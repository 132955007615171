import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { useQuery, useQueryClient } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AdminContext } from "../../Context/AdminContext"
import gold from "../../imges/gold.jpg"
import red from "../../imges/red.png"
import silver from "../../imges/silver.avif"
import bronze from "../../imges/bronze.jpg"
import logoo from "../../imges/logo.png"
import { FiAlignJustify } from "react-icons/fi";
import { BallTriangle } from 'react-loader-spinner'
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
function View() {
    let navigate = useNavigate();
    let { logOut } = useContext(AdminContext)
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768);
    let params = useParams();
    const queryClient = useQueryClient();
    const [addValue, setAddValue] = useState("");
    const [removeValue, setRemoveValue] = useState("");
    const [addPVValue, setAddPVValue] = useState("");
    const [newPass, setNewPass] = useState("");
    const [newPassEWallet, setNewPassEWallet] = useState("");
    const [visibility, setVisibility] = useState({});
    const [selectedMember, setSelectedMember] = useState(null); // For modal
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };


    const fetchData = async () => {
        try {
            setIsLoading(true); // Start loading
            const response = await axios.get(
                `https://together-v.com/api/v1/agent/getAgentByAdmin/${params.id}`,
                {
                    withCredentials: true,
                }
            );
            console.log("API Response:", response); // Log response to debug
            setData(response.data); // Set data
        } catch (error) {
            console.error("Error fetching data:", error);
            toast.error("Failed to fetch data. Please try again.");
        } finally {
            setIsLoading(false); // End loading
        }
    };
    
    useEffect(() => {
        fetchData();
    }, [params.id]); // Dependency on params.id
    

    console.log(data?.data)


    const handleAddBalance = async () => {
        try {
            const response = await axios.post(
                `https://together-v.com/api/v1/agent/addBalance/${params.id}`,
                { value: addValue },
                { withCredentials: true }
            );
    
            if (response.data.message === "Balance Updated successfully") {
                toast.success("Balance added successfully!");
                queryClient.invalidateQueries('getDataForUpdate');
                await fetchData(); // Fetch updated data
            }
        } catch (error) {
            toast.error("Failed to add balance. Please try again.");
        }
    };
    

    const handleRemoveBalance = async () => {
        try {
            const response = await axios.post(
                `https://together-v.com/api/v1/agent/deleteBalance/${params.id}`,
                { value: removeValue },
                { withCredentials: true }
            );
    
            if (response.data.message === "Balance Updated successfully") {
                toast.success("Balance removed successfully!");
                queryClient.invalidateQueries('getDataForUpdate');
                await fetchData(); // Fetch updated data
            }
        } catch (error) {
            toast.error("Failed to remove balance. Please try again.");
        }
    };
    


    const handleAddPv = async () => {
        try {
            const response = await axios.post(
                `https://together-v.com/api/v1/agent/addPV/${params.id}`,
                { value: addPVValue },
                { withCredentials: true }
            );
    
            if (response.data.message === "PV Updated successfully") {
                toast.success("PV added successfully!");
                queryClient.invalidateQueries('getDataForUpdate');
                await fetchData(); // Fetch updated data
            }
        } catch (error) {
            toast.error("Failed to add PV. Please try again.");
        }
    };
    


    const handleNewPassword = async () => {
        try {
            const response = await axios.post(
                `https://together-v.com/api/v1/agent/updatePasswordByAdmin/${params.id}`,
                { newPass: newPass },
                { withCredentials: true }
            );

            if (response.data.message === "Password updated successfully") {
                toast.success("Password updated successfully!");
                queryClient.invalidateQueries('getDataForUpdate');
            }
        } catch (error) {
            toast.error("Failed to update Password. Please try again.");
            queryClient.invalidateQueries('getDataForUpdate');
        }
    };

    const handleNewPasswordEWallet = async () => {
        try {
            const response = await axios.post(
                `https://together-v.com/api/v1/agent/updateWalletPasswordByAdmin/${params.id}`,
                { newPass: newPassEWallet },
                { withCredentials: true }
            );

            if (response.data.message === "Password updated successfully") {
                toast.success("Password updated successfully!");
                queryClient.invalidateQueries('getDataForUpdate');
            }
        } catch (error) {
            toast.error("Failed to update Password. Please try again.");
            queryClient.invalidateQueries('getDataForUpdate');
        }
    };
    const toggleVisibility = (id) => {
        setVisibility((prev) => ({
            ...prev,
            [id]: !prev[id], // Toggle visibility for this node
        }));
    };

    const fetchMemberDetails = async (memberId) => {
        try {
            const response = await axios.get(
                `https://together-v.com/api/v1/agent/getAgentByAdmin/${memberId}`,
                { withCredentials: true }
            );
            setSelectedMember(response.data.data); // Set the selected member's data
        } catch (error) {
            toast.error("Failed to fetch member details.");
        }
    };


    function getAgentTree() {
        return axios.get(`https://together-v.com/api/v1/agent/getAgentTreeForAdmin/${params.id}`, {
            withCredentials: true,
        });
    }
    const { data: treeData, isLoading: isTreeLoading } = useQuery("getAgentTree", getAgentTree);

    const renderMember = (member) => {
        if (!member) return null; // Stop recursion if no member is provided

        const getImageByLevel = (level) => {
            switch (level) {
                case "red":
                    return red;
                case "gold":
                    return gold;
                case "silver":
                    return silver;
                case "bronze":
                    return bronze;
                default:
                    return "assets/img/default.jpg";
            }
        };

        const navigateToAddAgent = (parentId, position) => {
            navigate(`/AddAgentAdmin?parentId=${parentId}&position=${position}`);
        };

        return (
            <li key={member._id} style={{ listStyleType: "none" }}>
                {/* Current Member */}
                <div className="member-view-box" style={{ textAlign: "center", marginBottom: "20px" }}>
                    <div className="member-image">
                        <img
                            src={getImageByLevel(member.level)}
                            alt={member.level}
                            style={{ cursor: "pointer", width: "50px", height: "50px" }}
                            onClick={() => toggleVisibility(member._id)} // Toggle visibility
                        />
                        <div className="member-details mt-2">
                            <h3
                                className="fnt-10px"
                                style={{ cursor: "pointer", color: "blue" }}
                                onClick={() => fetchMemberDetails(member._id)} // Fetch details
                            >
                                01: {member._id}
                            </h3>
                        </div>
                    </div>

                    {/* Left and Right Children */}
                    {visibility[member._id] && (
                        <div
                            className="children-container"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            {/* Left Child */}
                            {member.leftChild ? (
                                <div className="child-detail" style={{ textAlign: "center" }}>
                                    <div className="member-image">
                                        <img
                                            src={getImageByLevel(member.level)}
                                            alt="Left Child"
                                            style={{ cursor: "pointer", width: "50px", height: "50px" }}
                                            onClick={() => fetchMemberDetails(member.leftChild)}
                                        />
                                        <div
                                            className="fnt-10px"
                                            style={{ cursor: "pointer", color: "blue" }}
                                            onClick={() => fetchMemberDetails(member.leftChild)}
                                        >
                                            02: {member.leftChild}
                                        </div>
                                        <ul style={{ display: "flex", justifyContent: "center", padding: 0 }}>
                                            {member.leftChild0 ? (
                                                <li style={{ margin: "0 10px" }} className="fnt-10px">
                                                    {renderMember(member.leftChild0)}
                                                </li>
                                            ) : (
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={() => navigateToAddAgent(member.leftChild, "left0")}
                                                >
                                                    +
                                                </button>
                                            )}
                                            {member.rightChild0 ? (
                                                <li style={{ margin: "0 10px" }} className="fnt-10px">
                                                    {renderMember(member.rightChild0)}
                                                </li>
                                            ) : (
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={() => navigateToAddAgent(member.leftChild, "right0")}
                                                >
                                                    +
                                                </button>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            ) : (
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => navigateToAddAgent(member._id, "leftChild")}
                                >
                                    +
                                </button>
                            )}

                            {/* Right Child */}
                            {member.rightChild ? (
                                <div className="child-detail" style={{ textAlign: "center" }}>
                                    <img
                                        src={getImageByLevel(member.level)}
                                        alt="Right Child"
                                        style={{
                                            cursor: "pointer",
                                            width: "50px",
                                            height: "50px",
                                        }}
                                        onClick={() => fetchMemberDetails(member.rightChild)}
                                    />
                                    <div
                                        className="fnt-10px"
                                        style={{ cursor: "pointer", color: "blue" }}
                                        onClick={() => fetchMemberDetails(member.rightChild)}
                                    >
                                        03: {member.rightChild}
                                    </div>
                                    <ul style={{ display: "flex", justifyContent: "center", padding: 0 }}>
                                        {member.leftChild1 ? (
                                            <li style={{ margin: "0 10px" }} className="fnt-10px">
                                                {renderMember(member.leftChild1)}
                                            </li>
                                        ) : (
                                            <button
                                                className="btn btn-primary btn-sm"
                                                onClick={() => navigateToAddAgent(member.rightChild, "left1")}
                                            >
                                                +
                                            </button>
                                        )}
                                        {member.rightChild1 ? (
                                            <li style={{ margin: "0 10px" }} className="fnt-10px">
                                                {renderMember(member.rightChild1)}
                                            </li>
                                        ) : (
                                            <button
                                                className="btn btn-primary btn-sm"
                                                onClick={() => navigateToAddAgent(member.rightChild, "right1")}
                                            >
                                                +
                                            </button>
                                        )}
                                    </ul>
                                </div>
                            ) : (
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => navigateToAddAgent(member._id, "rightChild")}
                                >
                                    +
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </li>
        );
    };

    const handleDelete = async () => {
        try {
            const response = await axios.get(
                `https://together-v.com/api/v1/agent/deleteAgent/${params.id}`,
                { withCredentials: true }
            );

            if (response.data.message === "Agent deleted successfully") {
                toast.success("Agent deleted successfully!");
                queryClient.invalidateQueries('getDataForUpdate');
            }
        } catch (error) {
            toast.error("Failed to delete Agent. Please try again.");
            queryClient.invalidateQueries('getDataForUpdate');
        }
    }
    const handleBlockActivate = async () => {
        try {
            const response = await axios.get(
                `https://together-v.com/api/v1/agent/blockPackageActivate/${params.id}`,
                { withCredentials: true }
            );

            if (response.data.message === "Agent blocked successfully") {
                toast.success("Agent blocked successfully!");
                queryClient.invalidateQueries('getDataForUpdate');
            }
        } catch (error) {
            toast.error("Failed to blocke Agent. Please try again.");
            queryClient.invalidateQueries('getDataForUpdate');
        }
    }
    const handleBlockLogin = async () => {
        try {
            const response = await axios.get(
                `https://together-v.com/api/v1/agent/blockLogin/${params.id}`,
                { withCredentials: true }
            );

            if (response.data.message === "Agent blocked successfully") {
                toast.success("Agent blocked successfully!");
                queryClient.invalidateQueries('getDataForUpdate');
            }
        } catch (error) {
            toast.error("Failed to blocke Agent. Please try again.");
            queryClient.invalidateQueries('getDataForUpdate');
        }
    }

    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charset="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                    <title>Agent Data</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <header id="header" className="header fixed-top d-flex align-items-center">
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <img
                                src={logoo}
                                alt="Logo"
                                className="logo d-flex align-items-center w-35"
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate('/DashboardAdmin')}
                            />
                        </div>
                        <h2 className="menu-icon" onClick={toggleSidebar}>
                            <FiAlignJustify />
                        </h2>
                    </div>
                </header>
            </header>


            <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/DashboardAdmin">
                            <i className="bi bi-grid"></i>
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Agents">
                            <i className="bi bi-grid"></i>
                            <span>All Agents</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEpin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Epin</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EPin">
                            <i className="bi bi-grid"></i>
                            <span>E-Pins</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EpinHistory">
                            <i className="bi bi-grid"></i>
                            <span>E-Pins History</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddProduct">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Product</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyTreeAdmin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>My Tree</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEvent">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Event</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProducts">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>All Products</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDrawRequest">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Withdraw Requests</span>
                        </Link>
                    </li>

                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Reports</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/MyTransfers">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>My Transfers</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllRequestsProducts">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Purchase</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/TransferRequests">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Transfers</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllWithDrawsAdmin">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Withdraws</span>
                                </Link>
                            </li>
                        </ul>
                    </li>


                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Filtration">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Filtration</span>
                        </Link>
                    </li>
                    <li className="nav-heading">Pages</li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllContactUs">
                            <i className="bi bi-person"></i>
                            <span>All Contact</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ProfileAdmin">
                            <i className="bi bi-person"></i>
                            <span>Profile</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" onClick={() => logOut()}>
                            <i className="bi bi-person"></i>
                            <span>Log Out</span>
                        </Link>
                    </li>
                </ul>
            </aside>

            <div id="main" className="bg-light-2">
                <div className="container mt-4">


                    <section className="section profile">
                        <div className="row justify-content-between">
                            <button
                                className="btn btn-danger mt-2 w-25 ms-5"
                                onClick={handleBlockActivate}
                            >
                                Block Activate
                            </button>
                            <button
                                className="btn btn-danger mt-2 w-25"
                                onClick={handleBlockLogin}
                            >
                                Block Login
                            </button>
                            <button
                                className="btn btn-danger mt-2 w-25 me-5"
                                onClick={handleDelete}
                            >
                                Delete
                            </button>
                        </div>
                        <div className="row justify-content-between">

                            <div className="col-xl-12">
                                <div className="card border-0">
                                    <div className="card-body p-3">
                                        <ul className="nav nav-tabs border-bottom" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link active"
                                                    id="overview-tap"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#profile-overview"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="profile-overview"
                                                    aria-selected="true"
                                                >
                                                    Edit Data
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="edit-tap"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#profile-edit"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="profile-edit"
                                                    aria-selected="false"
                                                >
                                                    Add/Remove Balance-pv
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="change-password"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#profile-change-password"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="profile-change-password"
                                                    aria-selected="false"
                                                >
                                                    Change Password
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="change-eWallet"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#profile-change-eWallet"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="profile-change-eWallet"
                                                    aria-selected="false"
                                                >
                                                    E-Wallet Password
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="agent-tree"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#profile-agent-tree"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="profile-agent-tree"
                                                    aria-selected="false"
                                                >
                                                    Agent Tree
                                                </button>
                                            </li>

                                        </ul>



                                        <div className="tab-content pt-2">

                                            <div class="tab-pane fade profile-overview show active" id="profile-overview" role="tabpanel" aria-labelledby="#overview-tap">
                                                <form
                                                    onSubmit={async (e) => {
                                                        e.preventDefault();
                                                        const updatedData = {
                                                            firstName: e.target.firstName.value,
                                                            secondName: e.target.secondName.value,
                                                            thirdName: e.target.thirdName.value,
                                                            lastName: e.target.lastName.value,
                                                            email: e.target.email.value,
                                                            phone: e.target.phone.value,
                                                            nationalId: e.target.nationalId.value,
                                                            address: e.target.address.value,
                                                            country: e.target.country.value,
                                                            governorate: e.target.governorate.value,
                                                            city: e.target.city.value,
                                                            inheritorName: e.target.inheritorName.value,
                                                            grandfatherInheritorName: e.target.grandfatherInheritorName.value,
                                                            zipCode: e.target.zipCode.value,
                                                        };

                                                        try {
                                                            const token = localStorage.getItem("AdminToken");

                                                            const { data } = await axios.post(
                                                                `https://together-v.com/api/v1/agent/updateAgentByAdmin/${params.id}`,
                                                                updatedData,
                                                                {
                                                                    withCredentials: true,
                                                                    headers: {
                                                                        Authorization: `Bearer ${token}`,
                                                                    },
                                                                }
                                                            );

                                                            if (data.message === "Agent updated successfully") {
                                                                toast.success("Profile updated successfully");
                                                            }
                                                        } catch (err) {
                                                            toast.error(err.response?.data?.message || "An error occurred");
                                                        }
                                                    }}
                                                >
                                                    <div
                                                        className="tab-pane fade active show"
                                                        id="all-booking"
                                                        role="tabpanel"
                                                    >
                                                        {isLoading ? <div className="'w-100 py-5 d-flex justify-content-center">
                                                            <BallTriangle
                                                                height={100}
                                                                width={100}
                                                                radius={5}
                                                                color="#4fa94d"
                                                                ariaLabel="ball-triangle-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClass=""
                                                                visible={true}
                                                            />
                                                        </div> : <>

                                                            <div className="row mb-3">
                                                                <label htmlFor="firstName" className="col-md-4 col-lg-3 col-form-label">
                                                                    First Name
                                                                </label>
                                                                <div className="col-md-8 col-lg-9">
                                                                    <input
                                                                        name="firstName"
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="firstName"
                                                                        defaultValue={data?.data?.firstName || ""}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <label for="secondName" class="col-md-4 col-lg-3 col-form-label">Second Name</label>
                                                                <div class="col-md-8 col-lg-9">
                                                                    <input
                                                                        name="secondName"
                                                                        type="text"
                                                                        class="form-control"
                                                                        id="secondName"
                                                                        defaultValue={data?.data?.secondName || ""}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <label for="thirdName" class="col-md-4 col-lg-3 col-form-label">Third Name</label>
                                                                <div class="col-md-8 col-lg-9">
                                                                    <input
                                                                        name="thirdName"
                                                                        type="text"
                                                                        class="form-control"
                                                                        id="thirdName"
                                                                        defaultValue={data?.data?.thirdName || ""}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <label for="lastName" class="col-md-4 col-lg-3 col-form-label">Last Name</label>
                                                                <div class="col-md-8 col-lg-9">
                                                                    <input
                                                                        name="lastName"
                                                                        type="text"
                                                                        class="form-control"
                                                                        id="lastName"
                                                                        defaultValue={data?.data?.lastName || ""}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <label for="country" class="col-md-4 col-lg-3 col-form-label">Country</label>
                                                                <div class="col-md-8 col-lg-9">
                                                                    <input
                                                                        name="country"
                                                                        type="text"
                                                                        class="form-control"
                                                                        id="country"
                                                                        defaultValue={data?.data?.country || ""}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <label for="phone" class="col-md-4 col-lg-3 col-form-label">Phone</label>
                                                                <div class="col-md-8 col-lg-9">
                                                                    <input
                                                                        name="phone"
                                                                        type="text"
                                                                        class="form-control"
                                                                        id="phone"
                                                                        defaultValue={data?.data?.phone || ""}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <label for="email" class="col-md-4 col-lg-3 col-form-label">Email</label>
                                                                <div class="col-md-8 col-lg-9">
                                                                    <input
                                                                        name="email"
                                                                        type="email"
                                                                        class="form-control"
                                                                        id="email"
                                                                        defaultValue={data?.data?.email || ""}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <label for="nationalId" class="col-md-4 col-lg-3 col-form-label">National Id</label>
                                                                <div class="col-md-8 col-lg-9">
                                                                    <input
                                                                        name="nationalId"
                                                                        type="text"
                                                                        class="form-control"
                                                                        id="nationalId"
                                                                        defaultValue={data?.data?.nationalId || ""}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div class="row mb-3">
                                                                <label for="address" class="col-md-4 col-lg-3 col-form-label">address</label>
                                                                <div class="col-md-8 col-lg-9">
                                                                    <input
                                                                        name="address"
                                                                        type="text"
                                                                        class="form-control"
                                                                        id="address"
                                                                        defaultValue={data?.data?.address || ""}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <label for="governorate" class="col-md-4 col-lg-3 col-form-label">governorate</label>
                                                                <div class="col-md-8 col-lg-9">
                                                                    <input
                                                                        name="governorate"
                                                                        type="text"
                                                                        class="form-control"
                                                                        id="governorate"
                                                                        defaultValue={data?.data?.governorate || ""}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <label for="city" class="col-md-4 col-lg-3 col-form-label">city</label>
                                                                <div class="col-md-8 col-lg-9">
                                                                    <input
                                                                        name="city"
                                                                        type="text"
                                                                        class="form-control"
                                                                        id="city"
                                                                        defaultValue={data?.data?.city || ""}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <label for="inheritorName" class="col-md-4 col-lg-3 col-form-label">inheritorName</label>
                                                                <div class="col-md-8 col-lg-9">
                                                                    <input
                                                                        name="inheritorName"
                                                                        type="text"
                                                                        class="form-control"
                                                                        id="inheritorName"
                                                                        defaultValue={data?.data?.inheritorName || ""}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <label for="grandfatherInheritorName" class="col-md-4 col-lg-3 col-form-label">grandfatherInheritorName</label>
                                                                <div class="col-md-8 col-lg-9">
                                                                    <input
                                                                        name="grandfatherInheritorName"
                                                                        type="text"
                                                                        class="form-control"
                                                                        id="grandfatherInheritorName"
                                                                        defaultValue={data?.data?.grandfatherInheritorName || ""}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <label for="zipCode" class="col-md-4 col-lg-3 col-form-label">zipCode</label>
                                                                <div class="col-md-8 col-lg-9">
                                                                    <input
                                                                        name="zipCode"
                                                                        type="text"
                                                                        class="form-control"
                                                                        id="zipCode"
                                                                        defaultValue={data?.data?.zipCode || ""}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="text-center">
                                                                <button type="submit" className="button dark-1 bg-blue-1 md text-white">
                                                                    Save Changes
                                                                </button>
                                                            </div>
                                                        </>}


                                                    </div>


                                                </form>
                                            </div>


                                            <div className="tab-pane fade profile-edit pt-3" id="profile-edit" role="tabpanel" aria-labelledby="#edit-tap">
                                                <div class="row mb-3">
                                                    <label for="balance" class="col-md-4 col-lg-3 col-form-label">balance</label>
                                                    <div class="col-md-8 col-lg-9">
                                                        <input
                                                            name="balance"
                                                            type="text"
                                                            class="form-control"
                                                            id="balance"
                                                            defaultValue={data?.data?.balance || ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <label for="commission" class="col-md-4 col-lg-3 col-form-label">commission</label>
                                                    <div class="col-md-8 col-lg-9">
                                                        <input
                                                            name="commission"
                                                            type="text"
                                                            class="form-control"
                                                            id="commission"
                                                            defaultValue={data?.data?.commission || ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <label for="pv" class="col-md-4 col-lg-3 col-form-label">pv</label>
                                                    <div class="col-md-8 col-lg-9">
                                                        <input
                                                            name="pv"
                                                            type="text"
                                                            class="form-control"
                                                            id="pv"
                                                            defaultValue={data?.data?.pv || ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <label for="commissionStatus" class="col-md-4 col-lg-3 col-form-label">commissionStatus</label>
                                                    <div class="col-md-8 col-lg-9">
                                                        <input
                                                            name="commissionStatus"
                                                            type="text"
                                                            class="form-control"
                                                            id="commissionStatus"
                                                            defaultValue={data?.data?.commissionStatus || ""}
                                                        />
                                                    </div>
                                                </div>
                                                {/* Add Balance */}
                                                <div className="row mb-3">
                                                    <label htmlFor="addBalance" className="col-md-4 col-lg-3 col-form-label">
                                                        Add Balance
                                                    </label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="addBalance"
                                                            type="number"
                                                            className="form-control"
                                                            id="addBalance"
                                                            placeholder="Enter amount to add"
                                                            onChange={(e) => setAddValue(e.target.value)}
                                                        />
                                                        <button
                                                            className="btn btn-success mt-2"
                                                            onClick={handleAddBalance}
                                                        >
                                                            Add Balance
                                                        </button>
                                                    </div>
                                                </div>

                                                {/* Remove Balance */}
                                                <div className="row mb-3">
                                                    <label htmlFor="removeBalance" className="col-md-4 col-lg-3 col-form-label">
                                                        Remove Balance
                                                    </label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="removeBalance"
                                                            type="number"
                                                            className="form-control"
                                                            id="removeBalance"
                                                            placeholder="Enter amount to remove"
                                                            onChange={(e) => setRemoveValue(e.target.value)}
                                                        />
                                                        <button
                                                            className="btn btn-danger mt-2"
                                                            onClick={handleRemoveBalance}
                                                        >
                                                            Remove Balance
                                                        </button>
                                                    </div>
                                                </div>

                                                {/* Add Pv */}
                                                <div className="row mb-3">
                                                    <label htmlFor="addPV" className="col-md-4 col-lg-3 col-form-label">
                                                        Add PV
                                                    </label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="addPV"
                                                            type="number"
                                                            className="form-control"
                                                            id="addPV"
                                                            placeholder="Enter amount to add"
                                                            onChange={(e) => setAddPVValue(e.target.value)}
                                                        />
                                                        <button
                                                            className="btn btn-success mt-2"
                                                            onClick={handleAddPv}
                                                        >
                                                            Add PV
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>




                                            <div className="tab-pane fade profile-change-password pt-3" id="profile-change-password" role="tabpanel" aria-labelledby="#change-password">                                              <div className="mb-3">

                                                <div className="row mb-3">
                                                    <label htmlFor="changePassword" className="col-md-4 col-lg-3 col-form-label">
                                                        Change Password
                                                    </label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="changePassword"
                                                            type="text"
                                                            className="form-control"
                                                            id="changePassword"
                                                            placeholder="Enter amount to add"
                                                            onChange={(e) => setNewPass(e.target.value)}
                                                        />
                                                        <button
                                                            className="btn btn-success mt-2"
                                                            onClick={handleNewPassword}
                                                        >
                                                            Change Password
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>



                                            </div>

                                            <div className="tab-pane fade profile-change-password pt-3" id="profile-change-eWallet" role="tabpanel" aria-labelledby="#change-eWallet">                                              <div className="mb-3">

                                                <div className="row mb-3">
                                                    <label htmlFor="passwordEwallet" className="col-md-4 col-lg-3 col-form-label">
                                                        Change E-Wallet Password
                                                    </label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="passwordEwallet"
                                                            type="text"
                                                            className="form-control"
                                                            id="passwordEwallet"
                                                            placeholder="Enter amount to add"
                                                            onChange={(e) => setNewPassEWallet(e.target.value)}
                                                        />
                                                        <button
                                                            className="btn btn-success mt-2"
                                                            onClick={handleNewPasswordEWallet}
                                                        >
                                                            Change Password
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>



                                            </div>

                                            <div className="tab-pane fade profile-change-password pt-3" id="profile-agent-tree" role="tabpanel" aria-labelledby="#agent-tree">
                                                <div className="mb-3">
                                                    <div className="body genealogy-body genealogy-scroll">
                                                        <div className="tab-pane fade active show" id="all-booking" role="tabpanel">
                                                            <div className="notice-container">

                                                                <div className="notice-icons">
                                                                    <div className="icon">
                                                                        <div className="circle green"></div>
                                                                        <span>Stater</span>
                                                                    </div>
                                                                    <div className="icon">
                                                                        <div className="circle bronze"></div>
                                                                        <span>Bronze</span>
                                                                    </div>
                                                                    <div className="icon">
                                                                        <div className="circle silver"></div>
                                                                        <span>Silver</span>
                                                                    </div>
                                                                    <div className="icon">
                                                                        <div className="circle gold"></div>
                                                                        <span>Gold</span>
                                                                    </div>
                                                                    <div className="icon">
                                                                        <div className="circle red"></div>
                                                                        <span>Inactive</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <h4 className="text-center mt-3">The Agent Tree:</h4>
                                                            {isTreeLoading ? (
                                                                <div className="w-100 py-5 d-flex justify-content-center">
                                                                    <BallTriangle
                                                                        height={100}
                                                                        width={100}
                                                                        radius={5}
                                                                        color="#4fa94d"
                                                                        ariaLabel="ball-triangle-loading"
                                                                        wrapperStyle={{}}
                                                                        wrapperClass=""
                                                                        visible={true}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div className="genealogy-tree">
                                                                    <ul>{treeData?.data?.data && renderMember(treeData.data.data)}</ul>
                                                                </div>
                                                            )}
                                                            <Modal show={!!selectedMember} onHide={() => setSelectedMember(null)}>
                                                                <Modal.Header closeButton>
                                                                    <Modal.Title>Member Details</Modal.Title>
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    {selectedMember ? (
                                                                        <div>
                                                                            <p><strong>ID:</strong> {selectedMember._id}</p>
                                                                            <p><strong>Name:</strong> {selectedMember.firstName} {selectedMember.secondName} {selectedMember.thirdName} {selectedMember.lastName}</p>
                                                                            <p><strong>Email:</strong> {selectedMember.email}</p>
                                                                            <p><strong>PV:</strong> {selectedMember.pv}</p>
                                                                            <p><strong>Balance:</strong> {selectedMember.balance}</p>
                                                                        </div>
                                                                    ) : (
                                                                        <p>Loading...</p>
                                                                    )}
                                                                </Modal.Body>
                                                                <Modal.Footer>
                                                                    <Button variant="secondary" onClick={() => setSelectedMember(null)}>
                                                                        Close
                                                                    </Button>
                                                                </Modal.Footer>
                                                            </Modal>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>


            </div>




        </>
    );
}

export default View;

import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import toast from "react-hot-toast";
import { AdminContext } from "../../Context/AdminContext"
import logoo from "../../imges/logo.png"
import { FiAlignJustify } from "react-icons/fi";
import { BallTriangle } from 'react-loader-spinner'


function UpdateProductImage() {
    const queryClient = useQueryClient();
    const { logOut } = useContext(AdminContext);
    const [newImages, setNewImages] = useState([]);
    const [isAdding, setIsAdding] = useState(false);
    const [isDeleting, setIsDeleting] = useState(null);
    const params = useParams();
    const [formData, setFormData] = useState({
        img: []
    });
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768); // Sidebar open for desktop by default
    let navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768); // Open sidebar on desktop, close on mobile
        };

        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const getCurrentimg = () => {
        return axios.get(`https://together-v.com/api/v1/product/getProduct/${params.id}`, {

            withCredentials: true,
        });
    };

    const { data } = useQuery("getCurrentimg", getCurrentimg);
    const deleteImg = async (path) => {
        setIsDeleting(path);
        try {
            const { data } = await axios.post(
                `https://together-v.com/api/v1/product/deleteImage/${params.id}`,
                { path },
                { withCredentials: true }
            );
            if (data.message === "Image uploaded successfully") {
                toast.success("Image deleted successfully");
                queryClient.invalidateQueries("getCurrentimg");
            } else {
                toast.error("Failed to delete the image");
            }
        } catch (error) {
            toast.error("An error occurred while deleting the image");
        } finally {
            setIsDeleting(null);
        }
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files); // Convert FileList to an array
        setFormData((prevData) => ({
            ...prevData,
            img: [...prevData.img, ...files], // Append new files to existing array
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData(); // Define data as a FormData instance

        // Append all selected images
        formData.img.forEach((file) => {
            data.append("img", file); // 'img' matches the backend key
        });
        setIsAdding(true)
        try {

            const token = localStorage.getItem("AdminToken"); // Retrieve the token (adjust the storage method if necessary)

            const response = await axios.post(
                `https://together-v.com/api/v1/product/addImages/${params.id}`,
                data,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
                    },
                    withCredentials: true,
                }
            );

            if (response.data.message) {
                toast.success("Images added successfully!");
                setFormData({
                    img: [], // Reset images
                });
                queryClient.invalidateQueries("getCurrentimg");
                setIsAdding(false)

            }
        } catch (error) {
            toast.error("Failed to add images. Please try again.");
            console.error(error);
            setIsAdding(false)

        }
    };



    return (
        <>
            <header id="header" class="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charset="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                    <title>Products</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <header id="header" class="header fixed-top d-flex align-items-center">
                    <div class="d-flex align-items-center justify-content-between">
                        <div>
                            <img
                                src={logoo}
                                alt="Logo"
                                className="logo d-flex align-items-center w-35"
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate('/DashboardAdmin')}
                            />
                        </div>
                        <h2 className="menu-icon" onClick={toggleSidebar}>
                            <FiAlignJustify />
                        </h2>
                    </div>
                </header>
            </header>


            <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/DashboardAdmin">
                            <i className="bi bi-grid"></i>
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Agents">
                            <i className="bi bi-grid"></i>
                            <span>All Agents</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEpin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Epin</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EPin">
                            <i className="bi bi-grid"></i>
                            <span>E-Pins</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EpinHistory">
                            <i className="bi bi-grid"></i>
                            <span>E-Pins History</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddProduct">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Product</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyTreeAdmin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>My Tree</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEvent">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Add Event</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProducts">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>All Products</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDrawRequest">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Withdraw Requests</span>
                        </Link>
                    </li>

                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Reports</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/MyTransfers">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>My Transfers</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllRequestsProducts">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Purchase</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/TransferRequests">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Transfers</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllWithDrawsAdmin">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>All Withdraws</span>
                                </Link>
                            </li>
                        </ul>
                    </li>


                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Filtration">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>Filtration</span>
                        </Link>
                    </li>
                    <li className="nav-heading">Pages</li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllContactUs">
                            <i className="bi bi-person"></i>
                            <span>All Contact</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ProfileAdmin">
                            <i className="bi bi-person"></i>
                            <span>Profile</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" onClick={() => logOut()}>
                            <i className="bi bi-person"></i>
                            <span>Log Out</span>
                        </Link>
                    </li>
                </ul>
            </aside>

            <section>
                <div className="bg-light-2">
                    <div className="pagetitle">
                        <h1>Update Product Images</h1>
                    </div>
                    <div className="container">
                        <div className="">
                            {Array.isArray(data?.data?.data?.imgs) ? (
                                data.data.data.imgs.map((imgUrl, index) => (
                                    <div key={index} className="col-lg-4 m-auto">
                                        <div className="mb-3 w-100 ">
                                            {/* Display the image */}
                                            <img
                                                src={imgUrl}
                                                alt={`Image ${index + 1}`}
                                                className="img-thumbnail"
                                                style={{ width: "100%", marginBottom: "10px" }}
                                            />
                                            {/* Delete button */}
                                            <button
                                                className="btn btn-danger w-100 mt-2"
                                                onClick={() => deleteImg(imgUrl)}
                                                disabled={isDeleting === imgUrl}
                                            >
                                                {isDeleting === imgUrl ? (
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <BallTriangle height={20} width={20} />
                                                        <span className="ms-2">Deleting...</span>
                                                    </div>
                                                ) : (
                                                    `Remove Photo ${index + 1}`
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="col-12">
                                    <p>No images available or an error occurred.</p>
                                </div>
                            )}
                        </div>

                        {/* Add Images Section */}
                        <div className="mt-3 ms-5 w-50 ms-auto">
                            <input
                                type="file"
                                multiple
                                onChange={handleFileChange}
                                className="form-control mb-3"
                            />
                            <button
                                className="btn btn-primary w-50"
                                onClick={handleSubmit}
                                disabled={isAdding}
                            >
                                {isAdding ? (
                                    <div className="d-flex justify-content-center align-items-center">
                                        <BallTriangle height={20} width={20} />
                                        <span className="ms-2">Uploading...</span>
                                    </div>
                                ) : (
                                    "Add Images"
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </section>





        </>
    );
}

export default UpdateProductImage;
